import { readOnlyEntity } from 'ngx-myia-core';
import { IUser } from './user.interface';

export function User(props: IUser): IUser {
    return readOnlyEntity({
        email: '',
        fullName: undefined,
        ...props
    });
}
