import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { getNewComponentId, matchesSelector } from 'ngx-myia-core';

@Component({
    selector: 'input-checkbox',
    styleUrls: ['./inputCheckbox.component.scss'],
    template: `
               <div class="switchSection" [ngClass]="{on:value}">
                   <label class="switch" [ngClass]="{disabled:isDisabled}"><input type="checkbox" [formControl]="control" (click)="onClick($event)" (change)="onStateChanged($event)" ><i [ngClass]="{animated: initialized}">&nbsp;<div class="reqMark fade"></div></i><span class="switchText" [innerHtml]="label"><span class="textStateBlock" *ngIf="textStates"><span class="value">{{(value ? textStates[0] : textStates[1])|trans}}</span></span></span></label>
                </div>
              `
})
export class InputCheckboxComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() label: (string | SafeHtml);
    @Input() textStates: Array<string> = ['Yes', 'No'];
    @Input() isRequired: boolean;
    @Input() formGroupRef: FormGroup;
    @Input() fieldName: string;
    @Input() validator: any;
    @Input() canValueChange: (value: boolean, clicked: boolean) => boolean;

    get value(): any {
        return this._value;
    }
    @Input() set value(v: any) {
        if ((v !== this._value || !this._valueInitialized) && (!this.canValueChange || this.canValueChange(v, false))) {
            this._value = v;
            if (this._valueInitialized) {
                this.valueChange.emit(v);
            }
            else {
                this._valueInitialized = true;
            }
            if (this.control) {
                this.control.setValue(this._value);
            }
        }
    }

    @Output() valueChange: any = new EventEmitter<boolean>();

    get isDisabled(): boolean {
        return this._disabled;
    }

    @Input('isDisabled') set isDisabled(val: boolean) {
        this._disabled = val;
        if (this.control) {
            if (val) {
                this.control.disable();
            }
            else {
                this.control.enable();
            }
        }
    }

    @Input() set classNames (value: string) {
        this._classNames = value;
        this.updateHostClasses();
    }

    control: FormControl;
    initialized: boolean;

    @HostBinding('class') hostClasses: string;

    private _value: boolean;
    private _valueInitialized: boolean;
    private _classNames: string = 'switch';
    private _disabled: boolean = false;
    private _controlName: string;

    constructor(private _changeDetectorRef: ChangeDetectorRef, private _builder: FormBuilder) {
    }

    onStateChanged($event: any) {
        const newValue = matchesSelector($event.target, ':checked');
        this.value = newValue;
        this._changeDetectorRef.detectChanges();
        this.control.markAsDirty();
    }

    ngOnInit(): void {
        this.updateHostClasses();
        if (!this.formGroupRef) {
            this.formGroupRef =  this._builder.group({});
        }
        const validators = this.validator ? [this.validator] : [];
        if (this.isRequired) {
            validators.push(Validators.requiredTrue);
        }
        this.control = new FormControl({value: this.value, disabled: this.isDisabled}, Validators.compose(validators));
        if (this.formGroupRef) {
            this._controlName = this.fieldName || getNewComponentId();
            this.formGroupRef.addControl(this._controlName, this.control);
        }
    }

    ngOnDestroy() {
        if (this.formGroupRef) {
            this.formGroupRef.removeControl(this._controlName);
        }
    }

    ngAfterViewInit() {
        this.initialized = true;
        this._changeDetectorRef.detectChanges();
    }

    onClick($event: any) {
        const currentValue = matchesSelector($event.target, ':checked');
        // will be checked
        if (this.canValueChange && !this.canValueChange(currentValue, true)) {
            // cancel click action
            $event.preventDefault();
        }
    }

    private updateHostClasses() {
        this.hostClasses = 'checkbox ' + (this._classNames || '');
    }

}
