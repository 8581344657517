
        <nav class="navbar navbar-default navbar-fixed-top" [ngClass]="{'navMenuCollapsed' : showNavMenu}">
            <div class="noPadding navbarContainer">
                <div class="navbar-header noMargin">
                    <button type="button" class="navbar-toggle collapsed" (click)="toggleMenu()" [ngClass]="{force: showCollapseMenu}">
                        <svg-icon name="dots"></svg-icon>
                    </button>
                    <img *ngIf="icon" [src]="icon" class="topBarIcon" [ngClass]="{withContentSidebar: contentHasSidebar}"/>
                    <button class="topBarBackBtn btn btn-link btn-topbar-button" *ngIf="showBackButton" [ngClass]="{withContentSidebar: contentHasSidebar}" (click)="navigateBack()" [tooltip]="backBtnTooltip|trans" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipContainer="body">
                        <svg-icon *ngIf="svgIcon" name="{{svgIcon}}"></svg-icon>
                    </button>
                    <div class="topBarTitleContainer" [ngClass]="{withBackButton: showBackButton || icon, withContentSidebar: contentHasSidebar}">
                        <div class="topBarTitle" *ngIf="title" (click)="toggleTitleListClicked()" [ngClass]="{withList:showTitleSelection}"><span class="titleText"><span class="titleContent">{{title}}</span><span *ngIf="subTitle" class="subTitle {{subTitleClass}}">{{subTitle}}</span></span>
                            <svg-icon className="agendaSelectionBtn" name="arrow-down-thin" *ngIf="showTitleSelection"></svg-icon>
                        </div>
                        <ng-content></ng-content>
                    </div>
                </div>
                <div id="navbar" class="navbar-collapse collapse" [ngClass]="{'in' : showNavMenu}">
                    <ul #navbarRight class="nav navbar-nav navbar-right" [ngClass]="{list: showCollapseMenu}">
                        <li *ngIf="isDebugModeEnabled">
                            <button class="btn btn-link btn-topbar-button debugTools" (click)="switchDevTools()" tooltip="Developer tools (Debug only)" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" tooltipContainer="body">
                                <svg-icon name="bug"></svg-icon>
                                <label>Debugging tools</label></button>
                        </li>
                        <li *ngIf="hasAdminRole && !hideAdminFeatures">
                            <button class="btn btn-link btn-topbar-button adminTools" (click)="switchAdminTools()" tooltip="Admin tools" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" tooltipContainer="body">
                                <svg-icon name="tools"></svg-icon>
                                <label>Admin tools</label></button>
                        </li>
                        <li>
                            <button class="btn btn-link btn-topbar-button buttonCultureSwitch" (click)="switchCulture()" [tooltip]="'Menu|ChangeLanguage'|trans" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" [tooltipDontHideOnChange]="true" tooltipContainer="body"><span
                                class="cultureBtn icon" *ngIf="!changingCulture">{{cultureService.currentCulture}}</span>
                                <progress-indicator-circle *ngIf="changingCulture" indicatorClass="extraSmall cultureLoader"></progress-indicator-circle>
                                <label>{{'Menu|ChangeLanguage' |trans}}</label></button>
                        </li>
                        <li *ngIf="showUserProfile">
                            <button class="btn btn-link btn-topbar-button withBG fixedHeight" (click)="showAccount()" [tooltipHtml]="'Menu|UserProfile'|trans: {email: email}" tooltipPlacement="bottom" tooltipClass="ttUserProfile" tooltipOffset="0,10" tooltipContainer="body">
                                <svg-icon name="user"></svg-icon>
                                <label [innerHtml]="'Menu|UserProfile'|trans: {email: email}|sanitizeHtml"></label></button>
                        </li>
                        <li *ngIf="showLogout">
                            <button class="btn btn-link btn-topbar-button logout" (click)="logout()" [tooltip]="'Menu|Logout'|trans" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" tooltipContainer="body">
                                <svg-icon name="logout"></svg-icon>
                                <label>{{'Menu|Logout' |trans}}</label></button>
                        </li>
                    </ul>
                </div><!--/.nav-collapse -->
                <dev-tools *ngIf="showDevToolsMenu" (close)="switchDevTools()"></dev-tools>
                <admin-tools *ngIf="showAdminToolsMenu" (close)="switchAdminTools()"></admin-tools>
            </div>
            <div class="notificationsContainer"></div>
        </nav>
    