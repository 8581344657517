import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ReduxStore } from 'ngx-myia-redux';
import { CultureService, LocalizationService } from 'ngx-myia-localization';
import { Unsubscribe } from 'redux';
import { Observable, Observer, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { setPageTitle } from '../redux/generalActions';
import { generalReducerKey, IGeneralState } from '../redux/generalReducers';

@Injectable({providedIn: 'root'})
export class PageTitleService {
    public title: Observable<string>;
    private _storeSubscription: Unsubscribe;

    constructor(title: Title, localizationService: LocalizationService, cultureService: CultureService, private _store: ReduxStore) {
        let titleObserver: Observer<string>;
        this.title = new Observable((observer: Observer<string>) => {
            titleObserver = observer;
            const generalState = _store.getAllStates()[generalReducerKey] as IGeneralState;
            titleObserver.next(generalState._pageTitle);
        }).pipe(share());

        const updateTitle = (generalState: IGeneralState) => {
            const currentTitle = generalState ? generalState._pageTitle : null;
            (currentTitle ? localizationService.get(currentTitle) : of(currentTitle)).subscribe(pageTitle => {
                title.setTitle(pageTitle);
                if (titleObserver) {
                    titleObserver.next(currentTitle);
                }
            });
        };

        this._storeSubscription = _store.subscribe((state: any) => {
            const generalState = state[generalReducerKey] as IGeneralState;
            updateTitle(generalState);
        }, [generalReducerKey]);

        cultureService.onChange.subscribe(() => {
            updateTitle(_store.getState(generalReducerKey));
        });
    }

    setTitle(title: string) {
        this._store.dispatch(setPageTitle(title));
    }

    setDefaultTitle() {
        this.setTitle('Myia_Agendas');
    }

}
