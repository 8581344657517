import { Injectable } from '@angular/core';
import { Config } from 'ngx-myia-core';

@Injectable({providedIn: 'root'})
export class HelpService {

    private readonly _helpUrl: string;

    private helpKeyMap = {
        en: {
            'Agenda|Agenda_List': '/agenda-list',
        },
        cs: {
            'Agenda|Agenda_List': '/agenda-seznam',
        }
    };

    constructor() {
        this._helpUrl = Config.get<string>('helpUrl');
    }

    getHelpUrl(key: string, culture: string) {
        let keyUrl = this.helpKeyMap[culture] && this.helpKeyMap[culture][key];
        return `${this._helpUrl}${keyUrl}`;
    }

}
