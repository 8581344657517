import { IAgendaCulture } from '../entities/agendaCulture.interface';

export const TABLE_DATA_SET_CULTURES = 'TABLE_DATA_SET_CULTURES';

export function setCulturesAction(cultures: Array<IAgendaCulture>) {
    return {
        type: TABLE_DATA_SET_CULTURES,
        cultures
    };
}

