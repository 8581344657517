import { Injectable } from '@angular/core';
import { setCulturesAction } from '../redux/tableDataActions';
import { IAgendaCulture } from '../entities/agendaCulture.interface';
import { BackendService } from 'ngx-myia-http';
import { Logger } from 'ngx-myia-core';
import { ReduxStore } from 'ngx-myia-redux';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';
import { ITableDataState, tableDataReducerKey } from '../redux/tableDataReducers';

@Injectable({providedIn: 'root'})
export class TableDataService {

    constructor(private _backendService: BackendService, private _logger: Logger, private _store: ReduxStore) {
    }

    getCultures(): Observable<Array<IAgendaCulture>> {
        const tableDataState = this._store.getState(tableDataReducerKey) as ITableDataState;
        return tableDataState && tableDataState.cultures ? of(tableDataState.cultures) :
            // of(
            //     [
            //         {
            //             "name": "aa",
            //             "nativeName": "Qafar"
            //         },
            //         {
            //             "name": "af",
            //             "nativeName": "Afrikaans"
            //         },
            //         {
            //             "name": "agq",
            //             "nativeName": "Aghem"
            //         },
            //         {
            //             "name": "ak",
            //             "nativeName": "Akan"
            //         },
            //         {
            //             "name": "am",
            //             "nativeName": "አማርኛ"
            //         },
            //         {
            //             "name": "ar",
            //             "nativeName": "العربية"
            //         },
            //         {
            //             "name": "arn",
            //             "nativeName": "Mapudungun"
            //         },
            //         {
            //             "name": "as",
            //             "nativeName": "অসমীয়া"
            //         },
            //         {
            //             "name": "asa",
            //             "nativeName": "Kipare"
            //         },
            //         {
            //             "name": "ast",
            //             "nativeName": "asturianu"
            //         },
            //         {
            //             "name": "az",
            //             "nativeName": "Azərbaycan­ılı"
            //         },
            //         {
            //             "name": "ba",
            //             "nativeName": "Башҡорт"
            //         },
            //         {
            //             "name": "bas",
            //             "nativeName": "Ɓàsàa"
            //         },
            //         {
            //             "name": "be",
            //             "nativeName": "Беларуская"
            //         },
            //         {
            //             "name": "bem",
            //             "nativeName": "Ichibemba"
            //         },
            //         {
            //             "name": "bez",
            //             "nativeName": "Hibena"
            //         },
            //         {
            //             "name": "bg",
            //             "nativeName": "български"
            //         },
            //         {
            //             "name": "bin",
            //             "nativeName": "Ẹ̀dó"
            //         },
            //         {
            //             "name": "bm",
            //             "nativeName": "Bamana"
            //         },
            //         {
            //             "name": "bn",
            //             "nativeName": "বাংলা"
            //         },
            //         {
            //             "name": "bo",
            //             "nativeName": "བོད་ཡིག"
            //         },
            //         {
            //             "name": "br",
            //             "nativeName": "brezhoneg"
            //         },
            //         {
            //             "name": "brx",
            //             "nativeName": "बड़ो"
            //         },
            //         {
            //             "name": "bs",
            //             "nativeName": "bosanski"
            //         },
            //         {
            //             "name": "byn",
            //             "nativeName": "ብሊን"
            //         },
            //         {
            //             "name": "ca",
            //             "nativeName": "català"
            //         },
            //         {
            //             "name": "cgg",
            //             "nativeName": "Rukiga"
            //         },
            //         {
            //             "name": "chr",
            //             "nativeName": "ᏣᎳᎩ"
            //         },
            //         {
            //             "name": "co",
            //             "nativeName": "Corsu"
            //         },
            //         {
            //             "name": "cs",
            //             "nativeName": "čeština"
            //         },
            //         {
            //             "name": "cy",
            //             "nativeName": "Cymraeg"
            //         },
            //         {
            //             "name": "da",
            //             "nativeName": "dansk"
            //         },
            //         {
            //             "name": "dav",
            //             "nativeName": "Kitaita"
            //         },
            //         {
            //             "name": "de",
            //             "nativeName": "Deutsch"
            //         },
            //         {
            //             "name": "dje",
            //             "nativeName": "Zarmaciine"
            //         },
            //         {
            //             "name": "dsb",
            //             "nativeName": "dolnoserbšćina"
            //         },
            //         {
            //             "name": "dua",
            //             "nativeName": "duálá"
            //         },
            //         {
            //             "name": "dv",
            //             "nativeName": "ދިވެހިބަސް"
            //         },
            //         {
            //             "name": "dyo",
            //             "nativeName": "joola"
            //         },
            //         {
            //             "name": "dz",
            //             "nativeName": "རྫོང་ཁ"
            //         },
            //         {
            //             "name": "ebu",
            //             "nativeName": "Kĩembu"
            //         },
            //         {
            //             "name": "ee",
            //             "nativeName": "eʋegbe"
            //         },
            //         {
            //             "name": "el",
            //             "nativeName": "Ελληνικά"
            //         },
            //         {
            //             "name": "en",
            //             "nativeName": "English"
            //         },
            //         {
            //             "name": "eo",
            //             "nativeName": "esperanto"
            //         },
            //         {
            //             "name": "es",
            //             "nativeName": "español"
            //         },
            //         {
            //             "name": "et",
            //             "nativeName": "eesti"
            //         },
            //         {
            //             "name": "eu",
            //             "nativeName": "euskara"
            //         },
            //         {
            //             "name": "ewo",
            //             "nativeName": "ewondo"
            //         },
            //         {
            //             "name": "fa",
            //             "nativeName": "فارسى"
            //         },
            //         {
            //             "name": "ff",
            //             "nativeName": "Fulah"
            //         },
            //         {
            //             "name": "fi",
            //             "nativeName": "suomi"
            //         },
            //         {
            //             "name": "fil",
            //             "nativeName": "Filipino"
            //         },
            //         {
            //             "name": "fo",
            //             "nativeName": "føroyskt"
            //         },
            //         {
            //             "name": "fr",
            //             "nativeName": "français"
            //         },
            //         {
            //             "name": "fur",
            //             "nativeName": "furlan"
            //         },
            //         {
            //             "name": "fy",
            //             "nativeName": "Frysk"
            //         },
            //         {
            //             "name": "ga",
            //             "nativeName": "Gaeilge"
            //         },
            //         {
            //             "name": "gd",
            //             "nativeName": "Gàidhlig"
            //         },
            //         {
            //             "name": "gl",
            //             "nativeName": "galego"
            //         },
            //         {
            //             "name": "gn",
            //             "nativeName": "Avañe’ẽ"
            //         },
            //         {
            //             "name": "gsw",
            //             "nativeName": "Elsässisch"
            //         },
            //         {
            //             "name": "gu",
            //             "nativeName": "ગુજરાતી"
            //         },
            //         {
            //             "name": "guz",
            //             "nativeName": "Ekegusii"
            //         },
            //         {
            //             "name": "gv",
            //             "nativeName": "Gaelg"
            //         },
            //         {
            //             "name": "ha",
            //             "nativeName": "Hausa"
            //         },
            //         {
            //             "name": "haw",
            //             "nativeName": "ʻŌlelo Hawaiʻi"
            //         },
            //         {
            //             "name": "he",
            //             "nativeName": "עברית"
            //         },
            //         {
            //             "name": "hi",
            //             "nativeName": "हिंदी"
            //         },
            //         {
            //             "name": "hr",
            //             "nativeName": "hrvatski"
            //         },
            //         {
            //             "name": "hsb",
            //             "nativeName": "hornjoserbšćina"
            //         },
            //         {
            //             "name": "hu",
            //             "nativeName": "magyar"
            //         },
            //         {
            //             "name": "hy",
            //             "nativeName": "Հայերեն"
            //         },
            //         {
            //             "name": "ia",
            //             "nativeName": "interlingua"
            //         },
            //         {
            //             "name": "ibb",
            //             "nativeName": "Ibibio-Efik"
            //         },
            //         {
            //             "name": "id",
            //             "nativeName": "Bahasa Indonesia"
            //         },
            //         {
            //             "name": "ig",
            //             "nativeName": "Igbo"
            //         },
            //         {
            //             "name": "ii",
            //             "nativeName": "ꆈꌠꁱꂷ"
            //         },
            //         {
            //             "name": "is",
            //             "nativeName": "íslenska"
            //         },
            //         {
            //             "name": "it",
            //             "nativeName": "italiano"
            //         },
            //         {
            //             "name": "iu",
            //             "nativeName": "Inuktitut"
            //         },
            //         {
            //             "name": "ja",
            //             "nativeName": "日本語"
            //         },
            //         {
            //             "name": "jgo",
            //             "nativeName": "Ndaꞌa"
            //         },
            //         {
            //             "name": "jmc",
            //             "nativeName": "Kimachame"
            //         },
            //         {
            //             "name": "jv",
            //             "nativeName": "Basa Jawa"
            //         },
            //         {
            //             "name": "ka",
            //             "nativeName": "ქართული"
            //         },
            //         {
            //             "name": "kab",
            //             "nativeName": "Taqbaylit"
            //         },
            //         {
            //             "name": "kam",
            //             "nativeName": "Kikamba"
            //         },
            //         {
            //             "name": "kde",
            //             "nativeName": "Chimakonde"
            //         },
            //         {
            //             "name": "kea",
            //             "nativeName": "kabuverdianu"
            //         },
            //         {
            //             "name": "khq",
            //             "nativeName": "Koyra ciini"
            //         },
            //         {
            //             "name": "ki",
            //             "nativeName": "Gikuyu"
            //         },
            //         {
            //             "name": "kk",
            //             "nativeName": "қазақ тілі"
            //         },
            //         {
            //             "name": "kkj",
            //             "nativeName": "kakɔ"
            //         },
            //         {
            //             "name": "kl",
            //             "nativeName": "kalaallisut"
            //         },
            //         {
            //             "name": "kln",
            //             "nativeName": "Kalenjin"
            //         },
            //         {
            //             "name": "km",
            //             "nativeName": "ភាសាខ្មែរ"
            //         },
            //         {
            //             "name": "kn",
            //             "nativeName": "ಕನ್ನಡ"
            //         },
            //         {
            //             "name": "ko",
            //             "nativeName": "한국어"
            //         },
            //         {
            //             "name": "kok",
            //             "nativeName": "कोंकणी"
            //         },
            //         {
            //             "name": "kr",
            //             "nativeName": "Kanuri"
            //         },
            //         {
            //             "name": "ks",
            //             "nativeName": "کٲشُر"
            //         },
            //         {
            //             "name": "ksb",
            //             "nativeName": "Kishambaa"
            //         },
            //         {
            //             "name": "ksf",
            //             "nativeName": "rikpa"
            //         },
            //         {
            //             "name": "ksh",
            //             "nativeName": "Kölsch"
            //         },
            //         {
            //             "name": "ku",
            //             "nativeName": "کوردیی ناوەڕاست"
            //         },
            //         {
            //             "name": "kw",
            //             "nativeName": "kernewek"
            //         },
            //         {
            //             "name": "ky",
            //             "nativeName": "Кыргыз"
            //         },
            //         {
            //             "name": "la",
            //             "nativeName": "lingua latīna"
            //         },
            //         {
            //             "name": "lag",
            //             "nativeName": "Kɨlaangi"
            //         },
            //         {
            //             "name": "lb",
            //             "nativeName": "Lëtzebuergesch"
            //         },
            //         {
            //             "name": "lg",
            //             "nativeName": "Luganda"
            //         },
            //         {
            //             "name": "lkt",
            //             "nativeName": "Lakȟólʼiyapi"
            //         },
            //         {
            //             "name": "ln",
            //             "nativeName": "lingála"
            //         },
            //         {
            //             "name": "lo",
            //             "nativeName": "ລາວ"
            //         },
            //         {
            //             "name": "lt",
            //             "nativeName": "lietuvių"
            //         },
            //         {
            //             "name": "lu",
            //             "nativeName": "Tshiluba"
            //         },
            //         {
            //             "name": "luo",
            //             "nativeName": "Dholuo"
            //         },
            //         {
            //             "name": "luy",
            //             "nativeName": "Luluhia"
            //         },
            //         {
            //             "name": "lv",
            //             "nativeName": "latviešu"
            //         },
            //         {
            //             "name": "mas",
            //             "nativeName": "Maa"
            //         },
            //         {
            //             "name": "mer",
            //             "nativeName": "Kĩmĩrũ"
            //         },
            //         {
            //             "name": "mfe",
            //             "nativeName": "kreol morisien"
            //         },
            //         {
            //             "name": "mg",
            //             "nativeName": "Malagasy"
            //         },
            //         {
            //             "name": "mgh",
            //             "nativeName": "Makua"
            //         },
            //         {
            //             "name": "mgo",
            //             "nativeName": "metaʼ"
            //         },
            //         {
            //             "name": "mi",
            //             "nativeName": "Reo Māori"
            //         },
            //         {
            //             "name": "mk",
            //             "nativeName": "македонски"
            //         },
            //         {
            //             "name": "ml",
            //             "nativeName": "മലയാളം"
            //         },
            //         {
            //             "name": "mn",
            //             "nativeName": "Монгол хэл"
            //         },
            //         {
            //             "name": "mni",
            //             "nativeName": "মৈতৈলোন্"
            //         },
            //         {
            //             "name": "moh",
            //             "nativeName": "Kanien’kéha"
            //         },
            //         {
            //             "name": "mr",
            //             "nativeName": "मराठी"
            //         },
            //         {
            //             "name": "ms",
            //             "nativeName": "Bahasa Melayu"
            //         },
            //         {
            //             "name": "mt",
            //             "nativeName": "Malti"
            //         },
            //         {
            //             "name": "mua",
            //             "nativeName": "MUNDAŊ"
            //         },
            //         {
            //             "name": "my",
            //             "nativeName": "ဗမာ"
            //         },
            //         {
            //             "name": "naq",
            //             "nativeName": "Khoekhoegowab"
            //         },
            //         {
            //             "name": "nb",
            //             "nativeName": "norsk bokmål"
            //         },
            //         {
            //             "name": "nd",
            //             "nativeName": "isiNdebele"
            //         },
            //         {
            //             "name": "ne",
            //             "nativeName": "नेपाली"
            //         },
            //         {
            //             "name": "nl",
            //             "nativeName": "Nederlands"
            //         },
            //         {
            //             "name": "nmg",
            //             "nativeName": "Kwasio"
            //         },
            //         {
            //             "name": "nn",
            //             "nativeName": "nynorsk"
            //         },
            //         {
            //             "name": "nnh",
            //             "nativeName": "Shwóŋò ngiembɔɔn"
            //         },
            //         {
            //             "name": "no",
            //             "nativeName": "norsk"
            //         },
            //         {
            //             "name": "nqo",
            //             "nativeName": "ߒߞߏ"
            //         },
            //         {
            //             "name": "nr",
            //             "nativeName": "isiNdebele"
            //         },
            //         {
            //             "name": "nso",
            //             "nativeName": "Sesotho sa Leboa"
            //         },
            //         {
            //             "name": "nus",
            //             "nativeName": "Thok Nath"
            //         },
            //         {
            //             "name": "nyn",
            //             "nativeName": "Runyankore"
            //         },
            //         {
            //             "name": "oc",
            //             "nativeName": "Occitan"
            //         },
            //         {
            //             "name": "om",
            //             "nativeName": "Oromoo"
            //         },
            //         {
            //             "name": "or",
            //             "nativeName": "ଓଡ଼ିଆ"
            //         },
            //         {
            //             "name": "os",
            //             "nativeName": "Ирон æвзаг"
            //         },
            //         {
            //             "name": "pa",
            //             "nativeName": "ਪੰਜਾਬੀ"
            //         },
            //         {
            //             "name": "pap",
            //             "nativeName": "Papiamentu"
            //         },
            //         {
            //             "name": "pl",
            //             "nativeName": "polski"
            //         },
            //         {
            //             "name": "prs",
            //             "nativeName": "درى"
            //         },
            //         {
            //             "name": "ps",
            //             "nativeName": "پښتو"
            //         },
            //         {
            //             "name": "pt",
            //             "nativeName": "português"
            //         },
            //         {
            //             "name": "quc",
            //             "nativeName": "K'iche'"
            //         },
            //         {
            //             "name": "quz",
            //             "nativeName": "runasimi"
            //         },
            //         {
            //             "name": "rm",
            //             "nativeName": "rumantsch"
            //         },
            //         {
            //             "name": "rn",
            //             "nativeName": "Ikirundi"
            //         },
            //         {
            //             "name": "ro",
            //             "nativeName": "română"
            //         },
            //         {
            //             "name": "rof",
            //             "nativeName": "Kihorombo"
            //         },
            //         {
            //             "name": "ru",
            //             "nativeName": "русский"
            //         },
            //         {
            //             "name": "rw",
            //             "nativeName": "Kinyarwanda"
            //         },
            //         {
            //             "name": "rwk",
            //             "nativeName": "Kiruwa"
            //         },
            //         {
            //             "name": "sa",
            //             "nativeName": "संस्कृत"
            //         },
            //         {
            //             "name": "sah",
            //             "nativeName": "Саха"
            //         },
            //         {
            //             "name": "saq",
            //             "nativeName": "Kisampur"
            //         },
            //         {
            //             "name": "sbp",
            //             "nativeName": "Ishisangu"
            //         },
            //         {
            //             "name": "sd",
            //             "nativeName": "سنڌي"
            //         },
            //         {
            //             "name": "se",
            //             "nativeName": "davvisámegiella"
            //         },
            //         {
            //             "name": "seh",
            //             "nativeName": "sena"
            //         },
            //         {
            //             "name": "ses",
            //             "nativeName": "Koyraboro senni"
            //         },
            //         {
            //             "name": "sg",
            //             "nativeName": "Sängö"
            //         },
            //         {
            //             "name": "shi",
            //             "nativeName": "ⵜⴰⵎⴰⵣⵉⵖⵜ"
            //         },
            //         {
            //             "name": "si",
            //             "nativeName": "සිංහල"
            //         },
            //         {
            //             "name": "sk",
            //             "nativeName": "slovenčina"
            //         },
            //         {
            //             "name": "sl",
            //             "nativeName": "slovenščina"
            //         },
            //         {
            //             "name": "sma",
            //             "nativeName": "åarjelsaemiengïele"
            //         },
            //         {
            //             "name": "smj",
            //             "nativeName": "julevusámegiella"
            //         },
            //         {
            //             "name": "smn",
            //             "nativeName": "anarâškielâ"
            //         },
            //         {
            //             "name": "sms",
            //             "nativeName": "sää´mǩiõll"
            //         },
            //         {
            //             "name": "sn",
            //             "nativeName": "chiShona"
            //         },
            //         {
            //             "name": "so",
            //             "nativeName": "Soomaali"
            //         },
            //         {
            //             "name": "sq",
            //             "nativeName": "shqip"
            //         },
            //         {
            //             "name": "sr",
            //             "nativeName": "srpski"
            //         },
            //         {
            //             "name": "ss",
            //             "nativeName": "Siswati"
            //         },
            //         {
            //             "name": "ssy",
            //             "nativeName": "Saho"
            //         },
            //         {
            //             "name": "st",
            //             "nativeName": "Sesotho"
            //         },
            //         {
            //             "name": "sv",
            //             "nativeName": "svenska"
            //         },
            //         {
            //             "name": "sw",
            //             "nativeName": "Kiswahili"
            //         },
            //         {
            //             "name": "swc",
            //             "nativeName": "Kiswahili ya Kongo"
            //         },
            //         {
            //             "name": "syr",
            //             "nativeName": "ܣܘܪܝܝܐ"
            //         },
            //         {
            //             "name": "ta",
            //             "nativeName": "தமிழ்"
            //         },
            //         {
            //             "name": "te",
            //             "nativeName": "తెలుగు"
            //         },
            //         {
            //             "name": "teo",
            //             "nativeName": "Kiteso"
            //         },
            //         {
            //             "name": "tg",
            //             "nativeName": "Тоҷикӣ"
            //         },
            //         {
            //             "name": "th",
            //             "nativeName": "ไทย"
            //         },
            //         {
            //             "name": "ti",
            //             "nativeName": "ትግርኛ"
            //         },
            //         {
            //             "name": "tig",
            //             "nativeName": "ትግረ"
            //         },
            //         {
            //             "name": "tk",
            //             "nativeName": "Türkmen dili"
            //         },
            //         {
            //             "name": "tn",
            //             "nativeName": "Setswana"
            //         },
            //         {
            //             "name": "to",
            //             "nativeName": "lea fakatonga"
            //         },
            //         {
            //             "name": "tr",
            //             "nativeName": "Türkçe"
            //         },
            //         {
            //             "name": "ts",
            //             "nativeName": "Xitsonga"
            //         },
            //         {
            //             "name": "tt",
            //             "nativeName": "Татар"
            //         },
            //         {
            //             "name": "twq",
            //             "nativeName": "Tasawaq senni"
            //         },
            //         {
            //             "name": "tzm",
            //             "nativeName": "Tamazight"
            //         },
            //         {
            //             "name": "ug",
            //             "nativeName": "ئۇيغۇرچە"
            //         },
            //         {
            //             "name": "uk",
            //             "nativeName": "українська"
            //         },
            //         {
            //             "name": "ur",
            //             "nativeName": "اُردو"
            //         },
            //         {
            //             "name": "uz",
            //             "nativeName": "O'zbekcha"
            //         },
            //         {
            //             "name": "vai",
            //             "nativeName": "ꕙꔤ"
            //         },
            //         {
            //             "name": "ve",
            //             "nativeName": "Tshivenḓa"
            //         },
            //         {
            //             "name": "vi",
            //             "nativeName": "Tiếng Việt"
            //         },
            //         {
            //             "name": "vo",
            //             "nativeName": "Volapük"
            //         },
            //         {
            //             "name": "vun",
            //             "nativeName": "Kyivunjo"
            //         },
            //         {
            //             "name": "wae",
            //             "nativeName": "Walser"
            //         },
            //         {
            //             "name": "wal",
            //             "nativeName": "ወላይታቱ"
            //         },
            //         {
            //             "name": "wo",
            //             "nativeName": "Wolof"
            //         },
            //         {
            //             "name": "xh",
            //             "nativeName": "isiXhosa"
            //         },
            //         {
            //             "name": "xog",
            //             "nativeName": "Olusoga"
            //         },
            //         {
            //             "name": "yav",
            //             "nativeName": "nuasue"
            //         },
            //         {
            //             "name": "yi",
            //             "nativeName": "ייִדיש"
            //         },
            //         {
            //             "name": "yo",
            //             "nativeName": "Èdè Yorùbá"
            //         },
            //         {
            //             "name": "zgh",
            //             "nativeName": "ⵜⴰⵎⴰⵣⵉⵖⵜ"
            //         },
            //         {
            //             "name": "zh",
            //             "nativeName": "中文"
            //         },
            //         {
            //             "name": "zu",
            //             "nativeName": "isiZulu"
            //         }
            //     ]
            // )
            this._backendService.get<Array<any>>('/api/list/language')
                .pipe(
                    map((langData: any) => {
                        const languages = Object.keys(langData).map(lk => {
                            return {
                                "name": lk,
                                "nativeName": langData[lk]
                            };
                        });
                        this._store.dispatch(setCulturesAction(languages));
                        return languages;
                    }),
                    catchError(err => {
                        this._logger.error('Could not load languages list.');
                        return throwError(err);
                    })
                );
    }
}
