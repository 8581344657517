import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { closestParent, getNewComponentId } from 'ngx-myia-core';
import { CultureService, FormatDateService } from 'ngx-myia-localization';
import { Observable, of, Observer } from 'rxjs';

@Component({
    selector: 'input-date-field',
    styleUrls: ['./inputDateField.component.scss'],
    template: `
        <div class="textFieldGroup" [ngClass]="{required: isRequired}">
            <input type="text" #inputEl (blur)="onBlur()" (focus)="onFocus()" [formControl]="control" [readOnly]="readOnly" [ngClass]="{hasValue: valueStr, opened: isOpened, readOnly: readOnly}" autocomplete="off"/>
            <div class="reqMark line"></div>
            <div class="bar"></div>
            <label>{{label}}</label>
            <control-messages [messages]="control|validationErrors"></control-messages>
            <div #calWrap class="calendarWrapper"></div>
        </div>
    `
})
export class InputDateFieldComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() minDate: Date;

    @Input() set defaultPickerDate(val: Date) {
        if (this._defaultPickerDate !== val) {
            this._defaultPickerDate = val;
            this.destroyRome();
            this.initializeRome();
        }
    }

    @Input() isRequired: boolean;
    @Input() validator: any;

    get readOnly(): boolean {
        return this._readOnly;
    }

    @Input() set readOnly(val: boolean) {
        if (this._readOnly !== val) {
            this._readOnly = val;
            if (val) {
                this.destroyRome();
            } else {
                this.initializeRome();
            }
        }
    }

    @Input() label: string;
    @Input() formGroupRef: FormGroup;
    @Input() fieldName: string;

    @Input() set value(val: any) {
        // support moment objects
        if (val && val.toDate) {
            val = val.toDate();
        }
        if (this._value !== val) {
            this._value = val;
            this.setStrData(val);
        }
    }

    @Output() valueChange: any = new EventEmitter<Date>();

    @ViewChild('inputEl', {static: true}) _input: ElementRef;
    @ViewChild('calWrap', {static: true}) _calWrap: ElementRef;

    @Input() set classNames(value: string) {
        this._classNames = value;
        this.updateHostClasses();
    }

    @Output() blur: EventEmitter<void> = new EventEmitter<void>();
    @Output() focus: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class') hostClasses: string;

    public valueStr: string;
    public control: FormControl;
    public isOpened: boolean;

    private _romeObj: any;
    private _onCultureChange: any;
    private _value: Date;
    private _readOnly: boolean;
    private _classNames: string;
    private _defaultPickerDate: Date;

    private onRomeHideHandler: Function;
    private onRomeShowHandler: Function;
    private onRomeValueChangedHandler: Function;

    private onFieldFocusHandler: EventListener = this.onFieldFocus.bind(this);
    private _hideTimeout: any;
    private _controlName: string;

    private static _romeLib: any = null;


    constructor(private _elementRef: ElementRef, private _formatDateService: FormatDateService, private _changeDetectorRef: ChangeDetectorRef, private _cultureService: CultureService) {
        this.onRomeValueChangedHandler = this.onRomeValueChanged.bind(this);
        this.onRomeShowHandler = this.onRomeShow.bind(this);
        this.onRomeHideHandler = this.onRomeHide.bind(this);

        this._onCultureChange = this._cultureService.onChange.subscribe(() => {
            if (this._value) {
                this.setStrData(this._value);
            }
            setTimeout(() => {
                this.initializeRome();
            }, 0);
        });
    }

    ngOnInit() {
        this.updateHostClasses();
        const validators = this.validator ? [this.validator] : [];
        if (this.isRequired) {
            validators.push(Validators.required);
        }
        this.control = new FormControl({value: this.valueStr, disabled: false}, Validators.compose(validators));
        this.control.valueChanges.subscribe(this.updateStrData.bind(this));

        this.setStrData(this._value);
        this._controlName = this.fieldName || getNewComponentId();
        this.formGroupRef.addControl(this._controlName, this.control);
        this._elementRef.nativeElement.addEventListener('focus', this.onFieldFocusHandler, true);
    }

    ngOnDestroy() {
        if (this._romeObj) {
            this.destroyRome();
        }
        if (this._onCultureChange) {
            this._onCultureChange.unsubscribe();
            this._onCultureChange = undefined;
        }
        this._elementRef.nativeElement.removeEventListener('focus', this.onFieldFocusHandler, true);
        if (this.formGroupRef) {
            this.formGroupRef.removeControl(this._controlName);
        }
    }

    ngAfterViewInit() {
        this.initializeRome();
    }

    updateStrData(newStrValue: any) {
        if (this.valueStr !== newStrValue) {
            this.valueStr = newStrValue;
            if (this.control) {
                this.control.setValue(this.valueStr);
            }
            let newValue = this._formatDateService.parseLocalDate(newStrValue, true) as Date;
            this.updateData(newValue);
        }
    }

    onFocus() {
        this.focus.emit();
    }

    @HostListener('document:click', ['$event'])
    documentClick(e: Event) {
        if (!this.isOpened || this.dateFieldEventTarget(e)) {
            return;
        }
        if (this._romeObj) {
            this._romeObj.hide();
        }
    }

    onFieldFocus(e: Event) {
        if (this._hideTimeout) {
            clearTimeout(this._hideTimeout);
            this._hideTimeout = null;
        }
    }

    onBlur() {
        this.blur.emit();
    }

    private setStrData(value: Date) {
        const strValue = value ? this._formatDateService.getFormattedDate(value, this._formatDateService.getCurrentLocalDateFormat()) : '';
        this.updateStrData(strValue);
        this._changeDetectorRef.markForCheck();
    }


    private updateData(newValue: Date) {
        this._value = newValue;
        this.valueChange.emit(newValue);
        if (this.control) {
            this.control.markAsDirty();
        }
        if (this._romeObj) {
            this._romeObj.setValue(this._value);
        }
    }

    private onRomeValueChanged(newValue: any) {
        this.updateStrData(newValue);
        this._changeDetectorRef.markForCheck();
    }

    private onRomeShow() {
        this.isOpened = true;
        if (!this._romeObj.getDate() && this._defaultPickerDate) {
            this._romeObj.setValue(this._defaultPickerDate);
        }
        // disable tabIndex on rome buttons
        const buttons = this._romeObj.container.querySelectorAll('button');
        buttons.forEach((btn: any) => btn.tabIndex = -1);

        this._changeDetectorRef.markForCheck();
    }

    private onRomeHide() {
        this.isOpened = false;
        this._changeDetectorRef.markForCheck();
    }

    private destroyRome() {
        if (this._romeObj) {
            this._romeObj.off('data', this.onRomeValueChangedHandler);
            this._romeObj.off('show', this.onRomeShowHandler);
            this._romeObj.off('hide', this.onRomeHideHandler);
            this._romeObj.destroy();
        }
    }

    private initializeRome() {
        if (!this._readOnly) {

            if (this._romeObj) {
                this.destroyRome();
                this._input.nativeElement.removeAttribute('data-rome-id'); // this should do destroy method
            }
            this.loadRomeLib().subscribe(() => {
                this._romeObj = InputDateFieldComponent._romeLib(this._input.nativeElement,
                    {
                        appendTo: document.body,
                        autoHideOnClick: false,
                        autoHideOnBlur: true,
                        styles: {
                            back: 'rd-back noDefStyles', // add 'noDefStyles' css class to avoid default portal styles for button
                            next: 'rd-next noDefStyles' // add 'noDefStyles' css class to avoid default portal styles for button
                        },
                        min: this.minDate ? this._formatDateService.parseDate(this.minDate) : null,
                        inputFormat: this._formatDateService.getCurrentLocalDateFormat(),
                        timeFormat: this._formatDateService.getCurrentLocalTimeFormat(),
                    });
                this._romeObj.on('data', this.onRomeValueChangedHandler);
                this._romeObj.on('show', this.onRomeShowHandler);
                this._romeObj.on('hide', this.onRomeHideHandler);
            });
        }
    }

    private updateHostClasses() {
        this.hostClasses = 'inputField inputDateField ' + (this._classNames || '');
        this._changeDetectorRef.markForCheck();
    }

    private dateFieldEventTarget(e: Event): boolean {
        let target: any = e.target;
        if (this._romeObj && target === this._romeObj.associated) {
            return true;
        }
        if (closestParent(target, '.rd-container')) {
            return true;
        }
    }

    private loadRomeLib(): Observable<void> {
        return InputDateFieldComponent._romeLib ? of(null) : new Observable((observer: Observer<void>) => {
            (<any>require).ensure([], () => {
                InputDateFieldComponent._romeLib = require('./rome');
                observer.next(null);
                observer.complete();
            });
        })

    }

}

