import { readOnlyEntity } from 'ngx-myia-core';
import { IAgenda } from './agenda.interface';

export function Agenda(props: IAgenda): IAgenda {
    return readOnlyEntity({
        agendaId: null,
        name: null,
        createdUtc: null,
        defaultLanguage: null,
        languages: null,
        permission: null,
        ...props
    });
}
