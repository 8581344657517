import { Injector, OnInit } from '@angular/core';
import { ReduxStore, ReduxView } from 'ngx-myia-redux';
import { adminReducerKey, IAdminState } from '../redux/adminReducers';
import { AuthService } from '../services/authService';
import { MixinInjectable } from '../mixins/injectableMixin';

type Constructor<T = {}> = new (...args: any[]) => T;

export function AuthView<TBase extends Constructor<ReduxView>>(Base: TBase) {
    let _authService: AuthService;

    @MixinInjectable(Injector)
    class AuthViewClass extends Base implements OnInit {

        get baseViewName() {
            return Base['name'];
        }

        hasAdminRole: boolean;
        hideAdminFeatures: boolean;

        constructor(...args: any[]) {
            const injector = args[0];
            _authService = injector.get(AuthService);
            super(...args);
        }

        attachToStore(store: ReduxStore, storePaths?: Array<string>) {
            super.attachToStore(store, Array.from(new Set([...(storePaths || []), adminReducerKey]))); // get array of unique reducer keys
        }

        mapStateToProps(state: any) {
            const adminState = state[adminReducerKey] as IAdminState;
            this.hideAdminFeatures = this.getPropertyFromState(this.hideAdminFeatures, adminState._hideAdminFeatures);

            super.mapStateToProps(state);

            // check if admin menu should be visible
            _authService.checkRole('Admin', this.hasAdminRole).subscribe(hasRole => {
                this.hasAdminRole = hasRole;
                this.updateView();
            });

            return this.propertiesUpdated;
        }

    }
    return AuthViewClass;
}

export class AuthViewHelper {
    static hasAdminRole(view: any) : boolean {
        return !!view.hasAdminRole;
    }
    static hideAdminFeatures(view: any) : boolean {
        return !!view.hideAdminFeatures;
    }
}
