import { createPersistedState, IPersistedState, rehydrateReducerState, updateState } from 'ngx-myia-redux';
import { SET_PAGE_TITLE } from './generalActions';
import { REHYDRATE } from 'redux-persist';

export const generalReducerKey = 'general';

export interface IGeneralState extends IPersistedState {
    _pageTitle: string;
}

const initialState = createPersistedState<IGeneralState>(
    {
        _pageTitle: null
    });

function generalReducer(state = initialState, action: any) {
    switch (action.type) {
        case REHYDRATE:
            return rehydrateReducerState(generalReducerKey, state, action);
        case SET_PAGE_TITLE:
            return updateState(state, (newState: IGeneralState) => {
                newState._pageTitle = action.title;
            });
        default:
            return state;
    }
}

export default {
    [generalReducerKey]: generalReducer
};
