import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreUIModule } from 'ngx-myia-core-ui';
import { HttpModule } from 'ngx-myia-http'
import { cultureReducerKey, LocalizationModule } from 'ngx-myia-localization';
import { cultureReducers } from 'ngx-myia-localization';
import { ReduxFeatureModule, ReduxModule, ReduxStore } from 'ngx-myia-redux';
import { ToastModule } from 'ngx-myia-toast';
import { TooltipModule } from 'ngx-myia-tooltip';
import { AdminToolsComponent } from './components/adminTools';
import { AgGridEnumFilter } from './components/ag-grid/agGridEnumFilter.component';
import { ContextMenuDirective } from './components/context-menu/contextMenuDirective';
import { ContextMenuHolderComponent } from './components/context-menu/contextMenuHolder';
import { ContextMenuItemsFilterPipe } from './components/context-menu/contextMenuItemsFilterPipe';
import { ControlMessagesComponent } from './components/controlMessages';
import { DevToolsComponent } from './components/devTools';
import { EmptyComponent } from './components/emptyComponent';
import { HelpIconComponent } from './components/helpIcon';
import { HelpPositionPipe } from './components/helpPositionPipe';
import { InputCheckboxComponent } from './components/inputCheckbox';
import { InputDateFieldComponent } from './components/inputDateField';
import { InputDropDownComponent } from './components/inputDropDown';
import { InputFileComponent } from './components/inputFile';
import { InputMultiSelectionComponent } from './components/inputMultiSelection';
import { InputMultiSelectionItemPipe } from './components/inputMultiSelectionItemPipe';
import { InputRadioComponent } from './components/inputRadio';
import { InputTextFieldComponent } from './components/inputTextField';
import { InputTextFieldHasValuePipe } from './components/inputTextFieldHasValuePipe';
import { LanguagePickerComponent } from './components/languagePicker';
import { DROPDOWN_DIRECTIVES } from './components/ng2-bootstrap/dropdown';
import { SideBarComponent } from './components/sideBar';
import { SideBarItemComponent } from './components/sideBarItem';
import { StatusBarComponent } from './components/statusBar';
import { TopBarComponent } from './components/topBar';
import { ValidationErrorsPipe } from './components/validationErrorsPipe';
import { AutoSizeDirective } from './directives/autoSizeDirective';
import { MenuToggleDirective } from './directives/menuToggleDirective';
import { ScrollControlDirective } from './directives/scrollControlDirective';
import { StickyDirective } from './directives/stickyDirective';
import adminReducers from './redux/adminReducers';
import authReducers, { authReducerKey } from './redux/authReducers';
import debugReducers from './redux/debugReducers';
import generalReducers, { generalReducerKey } from './redux/generalReducers';
import tableDataReducers from './redux/tableDataReducers';
import statusBarReducers from './redux/statusBarReducers';

/* Redux configuration - must be provided as factory to be AOT compatible */
export function provideReducers() {
    return [adminReducers, authReducers, cultureReducers, debugReducers, generalReducers, statusBarReducers, tableDataReducers];
}
export function providePersistedReducersNames() {
    return [authReducerKey, generalReducerKey, cultureReducerKey];
}

let done = false;

@NgModule({
    imports: [
        CommonModule,
        CoreUIModule,
        FormsModule,
        HttpModule,
        LocalizationModule,
        ReactiveFormsModule,
        ReduxModule.forChild(provideReducers, providePersistedReducersNames),
        RouterModule,
        ToastModule,
        TooltipModule,
    ],
    declarations: [
        AgGridEnumFilter,
        AutoSizeDirective,
        EmptyComponent,
        MenuToggleDirective,
        AdminToolsComponent,
        ContextMenuItemsFilterPipe,
        ContextMenuDirective,
        ContextMenuHolderComponent,
        ControlMessagesComponent,
        DevToolsComponent,
        HelpIconComponent,
        InputCheckboxComponent,
        InputDropDownComponent,
        InputDateFieldComponent,
        InputFileComponent,
        InputMultiSelectionComponent,
        InputMultiSelectionItemPipe,
        InputRadioComponent,
        InputTextFieldComponent,
        InputTextFieldHasValuePipe,
        HelpPositionPipe,
        LanguagePickerComponent,
        ScrollControlDirective,
        SideBarComponent,
        SideBarItemComponent,
        StatusBarComponent,
        StickyDirective,
        TopBarComponent,
        ValidationErrorsPipe,
        DROPDOWN_DIRECTIVES,
    ],
    exports: [
        AgGridEnumFilter,
        AutoSizeDirective,
        MenuToggleDirective,
        EmptyComponent,
        AdminToolsComponent,
        ContextMenuItemsFilterPipe,
        ContextMenuDirective,
        ContextMenuHolderComponent,
        ControlMessagesComponent,
        DevToolsComponent,
        HelpIconComponent,
        InputCheckboxComponent,
        InputDropDownComponent,
        InputDateFieldComponent,
        InputFileComponent,
        InputMultiSelectionComponent,
        InputRadioComponent,
        InputTextFieldComponent,
        InputTextFieldHasValuePipe,
        HelpPositionPipe,
        LanguagePickerComponent,
        ScrollControlDirective,
        SideBarComponent,
        SideBarItemComponent,
        StatusBarComponent,
        StickyDirective,
        TopBarComponent,
        ValidationErrorsPipe,
        DROPDOWN_DIRECTIVES,
    ],
    providers: [
        // all services are singletons with @Injectable({ providedIn: 'root' })
    ],
    entryComponents: [
    ]
})
export class ViewSharedComponentsModule extends ReduxFeatureModule {
    constructor(reduxStore: ReduxStore, featureInjector: Injector) {
        super(done ? null : reduxStore, featureInjector);
        done = true;
    }
}

