import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormatDateService } from 'ngx-myia-localization';

const errorMessages: any = {
    'required': 'Validation|Required',
    'invalidEmailAddress': 'Validation|Invalid_Email_Address',
    'invalidUrl': 'Validation|Invalid_Url',
    'invalidDateTime': 'Validation|Invalid_DateTime',
    'passwordTooShort': 'Validation|Password_Too_Short',
    'wrongAutoCompleteValue': 'Validation|Wrong_AutoComplete_Value',
};

/**
 * Validation service
 */
@Injectable({ providedIn: 'root' })
export class ValidationService {

    private static Singleton: ValidationService;

    constructor(private _formatDateService: FormatDateService) {
        ValidationService.Singleton = this;
    }

    public urlValidator(control: FormControl | { value: string }) {
        if (!control.value || control.value.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i)) {
            return null;
        } else {
            return {'invalidUrl': true};
        }
    }

    public emailValidator(control: FormControl) {
        // RFC 2822 compliant regex
        if (!control.value || control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i)) {
            return null;
        } else {
            return {'invalidEmailAddress': true};
        }
    }

    public dateTimeValidator(control: FormControl) {
        if (!control.value || ValidationService.Singleton._formatDateService.isDateValid(control.value)) {
            return null;
        } else {
            return {'invalidDateTime': true};
        }
    }

    public timeValidator(control: FormControl) {
        if (!control.value || ValidationService.Singleton._formatDateService.isTimeValid(control.value)) {
            return null;
        } else {
            return {'invalidTime': true};
        }
    }

    public numberValidator(control: FormControl) {
        if (!control.value || !isNaN(control.value)) {
            return null;
        } else {
            return {'invalidNumber': true};
        }
    }

    public passwordLengthValidator(control: FormControl) {
        if (!control.value || control.value.length >= 6) {
            return null;
        } else {
            return {'passwordTooShort': true};
        }
    }


    public getValidatorErrorMessage(code: string) {
        return errorMessages[code] || code;
    }
}
