import { Component, ChangeDetectionStrategy, Input, ElementRef, OnInit, HostBinding } from '@angular/core';
import { openNewWindow } from 'ngx-myia-core';
import { CultureService } from 'ngx-myia-localization';
import { HelpService } from '../services/helpService';
import { HelpPosition } from './helpPosition';
import { HelpPositionPipe } from './helpPositionPipe';

@Component({
    selector: 'help-icon',
    styleUrls: ['./helpIcon.component.scss'],
    template: `
        <div class="wrapper" [tooltipHtml]="helpTooltip" [tooltipTrigger]="'hover click'" tooltipContainer="body" [tooltipPlacement]="popUpPlacement" tooltipOffset="0,5" [tooltipEnable]="popUpPlacement!=='hidden'" (click)="openHelp($event)">
            <svg-icon name="question" [ngStyle]="{color: color}"></svg-icon><ng-content></ng-content>
            <div class="helpImg" *ngIf="imageUrl" [ngStyle]="{'background-image': imageUrl ? 'url(\\'' + imageUrl + '\\')' : null}"></div>
            <tooltip-template #helpTooltip [ngClass]="{helpImgTooltip: !key, helpKeyTooltip: key}">
                <img class="helpImgFull" src="{{imageUrl}}" *ngIf="imageUrl">
                <div *ngIf="key">{{'Help|Click_To_Open_Help'|translate}}</div>
            </tooltip-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpIconComponent {
    @Input() imageUrl: string;
    @Input() popUpPlacement: string = 'auto';
    @Input() key: string;
    @Input() position: string | HelpPosition = HelpPosition.inline;
    @Input() color: string;
    @Input() className: string;

    @HostBinding('class')
    get helpClass() {
        return `helpIcon ${HelpPositionPipe.getClass(this.position)}${this.className ? ' ' + this.className : ''}`;
    }

    constructor(private _element: ElementRef, private _helpService: HelpService, private _cultureService: CultureService) {
    }

    openHelp(event: any) {
        if (this.key) {
            event.stopPropagation();
            event.preventDefault();
            const url = this._helpService.getHelpUrl(this.key, this._cultureService.currentCulture);
            openNewWindow(url);
        }
    }
}
