import { Component, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, AfterViewChecked } from '@angular/core';
import { ContextMenuService } from './contextMenuService';
import { Subject } from 'rxjs';

export interface IContextMenuItem {
    title?: string;
    icon?: string;
    data?: any;
    subject?: Subject<any>;
    fileInput?: boolean;
    hidden?: boolean;
    disabled?: boolean;
    css?: string;
    isDelimiter?: boolean;
}

export interface IFileInputActionData {
    data: any;
    event: any;
}

@Component({
    selector: 'context-menu-holder',
    styleUrls: ['./contextMenuHolder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<div class="cm_container">
                  <ul>
                    <li class="link {{link.css}}" *ngFor="let link of links | contextMenuItemsFilter; trackBy: trackLink" [ngClass]="{delimiter: link.isDelimiter, disabled :link.disabled}">
                        <div *ngIf="link.isDelimiter && link.title">{{link.title|trans:link.data}}</div>
                        <div class="item" *ngIf="!link.fileInput && !link.isDelimiter" (click)="itemClicked(link)"><svg-icon *ngIf="link.icon" name="{{link.icon}}" className="menuIcon"></svg-icon><span class="menuText">{{link.title|trans:link.data}}</span></div>
                        <div class="item" *ngIf="link.fileInput && !link.isDelimiter"><input-file [disabled]="link.disabled" (fileAdded)="fileAdded(link, $event)"><svg-icon *ngIf="link.icon" name="{{link.icon}}" className="menuIcon"></svg-icon><span class="menuText">{{link.title|trans:link.data}}</span></input-file></div>
                    </li>
                  </ul>
                </div>
              `
})
export class ContextMenuHolderComponent implements AfterViewChecked {
    links: Array<IContextMenuItem> = [];
    isShown = false;
    private mouseLocation: {left: number, top: number} = {left: 0, top: 0};

    constructor(private _contextMenuService: ContextMenuService, private _changeDetectorRef: ChangeDetectorRef, private _elementRef: ElementRef ) {
        _contextMenuService.show.subscribe(e => this.showMenu(e.event, e.obj));
    }

    ngAfterViewChecked() {
        this.setPosition();
    }

    get locationCss() {
        return {
            'display': this.isShown ? 'block' : 'none',
            left: this.mouseLocation.left + 'px',
            top: this.mouseLocation.top + 'px',
        };
    }

    @HostListener('document:click')
    documentClick() {
        this.clickedOutside();
    }

    @HostListener('document:scroll')
    documentScroll() {
        this.clickedOutside();
    }

    @HostListener('document:contextmenu')
    documentContextMenu() {
        this.clickedOutside();
    }

    @HostListener('window:resize')
    windowResize() {
        this.clickedOutside();
    }

    clickedOutside() {
        this.isShown = false;
        this._changeDetectorRef.markForCheck();
    }

    showMenu(event: any, links: Array<IContextMenuItem>) {
        this.isShown = true;
        this.links = links;
        this.setPosition(event);
        this._changeDetectorRef.markForCheck();
    }

    itemClicked(link: IContextMenuItem) {
        if (!link.disabled) {
            this.raiseItemClick(link);
        }
    }

    fileAdded(link: IContextMenuItem, $event: any) {
        this.raiseItemClick(link, {data: link.data, event: $event});
    }

    raiseItemClick(link: IContextMenuItem, data?: any) {
        link.subject.next(data || link.data);
    }

    trackLink(index: number, link: IContextMenuItem): string {
        return link.data || link.title;
    }

    private setPosition(e?: any) {
        if (!this._elementRef) {
            return;
        }
        const menu = this._elementRef.nativeElement.querySelector('.cm_container') as HTMLElement;

        if (!this.isShown || (!e && !this.mouseLocation) || !menu) {
            if (menu) {
                menu.style.display = 'none';
            }
            return;
        }

        let posx = this.mouseLocation ? this.mouseLocation.left : 0;
        let posy = this.mouseLocation ? this.mouseLocation.top : 0;

        if (e) {
            if (e.pageX || e.pageY) {
                posx = e.pageX;
                posy = e.pageY;
            } else if (e.clientX || e.clientY) {
                posx = e.clientX + document.body.scrollLeft +
                    document.documentElement.scrollLeft;
                posy = e.clientY + document.body.scrollTop +
                    document.documentElement.scrollTop;
            }
        }
        const menuWidth = menu.offsetWidth + 20;
        const menuHeight = menu.offsetHeight + 20;

        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if ((windowWidth - posx) < menuWidth) {
            posx = windowWidth - menuWidth;
        }

        if ((windowHeight - posy) < menuHeight) {
            posy = windowHeight - menuHeight;
        }

        if (!this.mouseLocation || this.mouseLocation.left !== posx || this.mouseLocation.top !== posy) {
            this.mouseLocation = {
                left: posx,
                top: posy
            };
            menu.style.display = 'block';
            menu.style.left = this.mouseLocation.left + 'px';
            menu.style.top = this.mouseLocation.top + 'px';
        }
    }

}
