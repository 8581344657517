import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ReduxStore } from 'ngx-myia-redux';
import { ToastManager } from 'ngx-myia-toast';
import { hideAdminFeaturesAction } from '../redux/adminActions';

@Component({
    selector: 'admin-tools',
    styleUrls: ['./adminTools.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
                <div class="_adminToolsMenu_">
                    <div class="panelTitle">Admin tools</div>
                    <div class="closeBtnWrapper">
                        <button class="btn btn-link btn-topbar-button" type="button" (click)="closeAdminTools()"><svg-icon name="cancel"></svg-icon></button>
                    </div>
                    <div class="section">
                        <div>
                            <input-checkbox label="Show admin features in portal" [value]="showAdminFeatureInPortal" [textStates]="false" (valueChange)="onShowAdminFeatureInPortalChanged($event)"></input-checkbox>
                        </div>
                    </div>
                </div>
    `
})
export class AdminToolsComponent {
    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    showAdminFeatureInPortal = true;

    constructor(private _toastManager: ToastManager, private _store: ReduxStore) {}

    closeAdminTools() {
        this.close.emit();
    }

    onShowAdminFeatureInPortalChanged(show: boolean) {
        if (!show) {
            this._store.dispatch(hideAdminFeaturesAction());
            this.closeAdminTools();
            this._toastManager.success('Admin features hidden. It will be activated back again when page reloaded.');
        }
    }
}

