import { IAgenda } from '@shared';

export const LOAD_AGENDAS_STARTED = 'LOAD_AGENDAS_STARTED';
export const LOAD_AGENDAS_COMPLETED = 'LOAD_AGENDAS_COMPLETED';

export function loadAgendasAction() {
    return {
        type: LOAD_AGENDAS_STARTED
    };
}

export function loadAgendasCompletedAction(loadedAgendas: ReadonlyArray<IAgenda>) {
    return {
        type: LOAD_AGENDAS_COMPLETED,
        loadedAgendas: loadedAgendas
    };
}
