import { Injectable } from '@angular/core';
import { Logger } from 'ngx-myia-core';
import { BackendService } from 'ngx-myia-http'
import { FormatDateService } from 'ngx-myia-localization';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Agenda } from '../entities/agenda';
import { IAgenda } from '../entities/agenda.interface';

@Injectable({ providedIn: 'root' })
export class AgendaListService {

    constructor(private _backendService: BackendService, private _formatDateService: FormatDateService, private _logger: Logger) {
    }

    getAgendas(): Observable<ReadonlyArray<IAgenda>> {
        // return mockup data
        // return new ScalarObservable(this.getMockupAgendas()).delay(2000); // wait 2s to return result
        return this._backendService.get<Array<IAgenda>>('/api/agenda')
            .pipe(
                map(data => {
                    const agendas = data.map((agenda: IAgenda) => {
                        return Agenda(agenda);
                    });
                    return agendas;
                }),
                catchError(err => {
                    this._logger.error('Could not load agendas.');
                    return throwError(err);
                })
            );

    }

    // private getMockupAgendas() {
    //     return [
    //         Agenda({agendaId: 'ev1', name: 'Event One'}),
    //         Agenda({agendaId: 'ev2', name: 'Event Two'}),
    //         Agenda({agendaId: 'ev3', name: 'Event Three'})
    //     ];
    // }

}

