import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config, getNewComponentId, Logger } from 'ngx-myia-core';
import { BackendService, IAuthState, ITokenData, ITokenService } from 'ngx-myia-http'
import { ReduxStore, setReduxStorage } from 'ngx-myia-redux';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, share } from 'rxjs/operators';

import { authCompletedAction, authFailedAction, authRefreshCompletedAction, authRefreshFailedAction, authRefreshStartedAction, authStartedAction } from '../redux/authActions';
import { authReducerKey } from '../redux/authReducers';

@Injectable({ providedIn: 'root' })
export class TokenService implements ITokenService {
    private _currentRefreshTokenRequest: Observable<ITokenData>;
    private _useOldAPI: boolean;

    constructor(private _backendService: BackendService, private _store: ReduxStore, private _logger: Logger) {
        this._useOldAPI = Config.get<boolean>('useOldWebAPI', false);
    }

    /**
     * Make API call to token service
     * @param {string} email - user email
     * @param {string} password
     * @param {string} rememberMe flag
     * @return {Observable} - http request
     */
    load(email: string, password: string, rememberMe: boolean) {
        return setReduxStorage(rememberMe ? 'local': 'session')
            .pipe(
                mergeMap(() => {
                    let headers = new HttpHeaders();
                    const clientId = this.generateClientId();
                    let data: any;
                    if (this._useOldAPI) {
                        data = `username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&grant_type=password&client_id=${clientId}`;
                        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
                    }
                    else {
                        data = {
                            username: email,
                            password: password,
                            grant_type: 'password',
                            applicationId: Config.get<string>('AUTH_APP_ID'),
                            client_id: clientId
                        }
                    }

                    this._store.dispatch(authStartedAction(email));
                    let request: Observable<any> = this._useOldAPI ? this._backendService.post(`${Config.get<string>('BASE_AUTH_API_URL')}/token`, data, {headers: headers, noAuth: true}) : this._backendService.postJSON(`${Config.get<string>('BASE_AUTH_API_URL')}/token`, data, {noAuth: true});
                    request = request
                        .pipe(
                            catchError(errResponse => {
                                if (errResponse && errResponse.error && typeof errResponse.error === 'string') {
                                    errResponse.error = JSON.parse(errResponse.error);
                                }
                                return throwError(errResponse);
                            }),
                            share()
                        );
                    request
                        .subscribe(
                            (tokensData: any) => {
                                this._store.dispatch(authCompletedAction(clientId, email, tokensData.access_token, tokensData.refresh_token));
                            },
                            error => {
                                this._store.dispatch(authFailedAction());
                                try {
                                    this._logger.error(`Could not load token: ${JSON.stringify(error)}`);
                                }
                                catch(e) {
                                    // error during error object serialization
                                    this._logger.error(`Could not load token: Unknown error`);
                                }
                            }
                        );

                    return request;
                })
            );
    }

    refreshToken(): Observable<ITokenData> {
        // check if 'refresh token' request already running
        if (this._currentRefreshTokenRequest) {
            this._logger.log('Reused existing request for \'refresh token\'.');
            return this._currentRefreshTokenRequest;
        }
        const authState = this._store.getState(authReducerKey) as IAuthState;
        if (authState) {
            const email = authState.email;
            const clientId = authState.clientId;
            const refreshToken = authState.refreshToken;
            if (email && refreshToken) {
                let headers = new HttpHeaders();
                let data: any;
                if (this._useOldAPI) {
                    data = `refresh_token=${refreshToken}&grant_type=refresh_token&client_id=${clientId}`;
                    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
                }
                else {
                    data = {
                        refresh_token: refreshToken,
                        grant_type: 'refresh_token',
                        applicationId: Config.get<string>('AUTH_APP_ID'),
                        client_id: clientId
                    }
                }

                this._store.dispatch(authRefreshStartedAction(refreshToken));
                this._currentRefreshTokenRequest = this._useOldAPI ? this._backendService.post<ITokenData>(`${Config.get<string>('BASE_AUTH_API_URL')}/token`, data, {headers: headers, noAuth: true}) : this._backendService.postJSON<ITokenData>(`${Config.get<string>('BASE_AUTH_API_URL')}/token`, data, {noAuth: true});
                this._currentRefreshTokenRequest = this._currentRefreshTokenRequest
                    .pipe(
                        mergeMap((data: ITokenData) => {
                            this._currentRefreshTokenRequest = null;
                            this._store.dispatch(authRefreshCompletedAction(email, data.access_token, data.refresh_token || refreshToken));
                            return of(null);
                        }),
                        catchError(err => {
                            this._currentRefreshTokenRequest = null;
                            this._store.dispatch(authRefreshFailedAction());
                            this._logger.warn('Could not refresh token.');
                            return throwError(err);
                        }),
                        share()
                    );
                return this._currentRefreshTokenRequest;
            }
        }
        this._logger.warn('Refresh token not available.');
        this._store.dispatch(authRefreshFailedAction());
        return throwError('Refresh token not available.');
    }

    _test_invalidate_token(invalidateToken: boolean, invalidateRefreshToken: boolean) {
        const authState = this._store.getState(authReducerKey) as IAuthState;
        if (authState) {
            const clientId = authState.clientId;
            const email = authState.email;
            const token = authState.token + (invalidateToken ? 'X' : '');
            const refreshToken = authState.refreshToken + (invalidateRefreshToken ? 'X' : '');
            this._store.dispatch(authCompletedAction(clientId, email, token, refreshToken));
        }
    }

    private generateClientId(): string {
        return getNewComponentId();
    }

}
