
                <div class="_adminToolsMenu_">
                    <div class="panelTitle">Admin tools</div>
                    <div class="closeBtnWrapper">
                        <button class="btn btn-link btn-topbar-button" type="button" (click)="closeAdminTools()"><svg-icon name="cancel"></svg-icon></button>
                    </div>
                    <div class="section">
                        <div>
                            <input-checkbox label="Show admin features in portal" [value]="showAdminFeatureInPortal" [textStates]="false" (valueChange)="onShowAdminFeatureInPortalChanged($event)"></input-checkbox>
                        </div>
                    </div>
                </div>
    