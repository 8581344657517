import { SET_APP_CULTURE } from 'ngx-myia-localization';
import { createState, updateState } from 'ngx-myia-redux';
import { TABLE_DATA_SET_CULTURES } from './tableDataActions';

export const tableDataReducerKey = 'tableData';

export interface ITableDataState {
    cultures: Array<any>;
}

const initialState = createState<ITableDataState>(
    {
        cultures: null,
    });

function tableDataReducer(state = initialState, action: any) {
    let newState;
    switch (action.type) {
        case SET_APP_CULTURE:
            // clear table data specific to culture -> load it again from server
            return initialState;
        case TABLE_DATA_SET_CULTURES:
            return updateState(state, (newState: ITableDataState) => {
                newState.cultures = action.cultures;
            });
        default:
            return state;
    }
}

export default {
    [tableDataReducerKey]: tableDataReducer
};
