import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { IAgendaCulture } from '@shared';
import { removeDiacritics, sortBy } from 'ngx-myia-core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { TableDataService } from '../services/tableDataService';

@Component({
    selector: 'language-picker',
    styleUrls: ['./languagePicker.component.scss'],
    template: `
        <input-dropdown [classNames]="classNames" [disabled]="culturesLoading" [items]="filteredLanguages" valuePath="name" itemTitlePath="nativeName" [value]="language"
            (valueChange)="currentLanguageChanged($event)"></input-dropdown>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePickerComponent implements OnInit, OnDestroy {
    culturesLoading: boolean;
    languages: Array<IAgendaCulture>;
    filteredLanguages: Array<IAgendaCulture>;

    @Input() classNames: string = 'languageList';

    @Input() language: string;
    @Output() languageChanged: EventEmitter<string> = new EventEmitter<string>();

    @Input() set supportedLanguages(value: Array<string>) {
        this._supportedLanguages = value;
        this.updateFilteredLanguages();
    }

    @Output() languagesLoaded: EventEmitter<Array<IAgendaCulture>> = new EventEmitter<Array<IAgendaCulture>>();

    private _supportedLanguages: Array<string>;

    constructor(private _changeDetectorRef: ChangeDetectorRef, private _tableDataService: TableDataService) {
    }

    ngOnInit() {
        this.loadCulturesList().subscribe();
    }

    ngOnDestroy() {
    }

    currentLanguageChanged(lang: string) {
        this.language = lang;
        this.languageChanged.emit(lang);
    }

    private loadCulturesList(): Observable<Array<IAgendaCulture>> {
        this.culturesLoading = true;
        this._changeDetectorRef.markForCheck();
        return this._tableDataService.getCultures()
            .pipe(
                map(cultures => {
                    const sortedCultures = sortBy<IAgendaCulture>(cultures, c => removeDiacritics(c.nativeName.toLowerCase()));
                    this.languages = sortedCultures as Array<IAgendaCulture>;
                    this.languagesLoaded.emit(this.languages);
                    this.updateFilteredLanguages();
                    return this.languages;
                }),
                finalize(() => {
                    this.culturesLoading = false;
                    this._changeDetectorRef.markForCheck();
                })
            );
    }

    private updateFilteredLanguages() {
        const langs = (this._supportedLanguages && this.languages ? this._supportedLanguages.map(sl => this.languages.find(l => l.name === sl)) : this.languages);
        this.filteredLanguages = langs ? langs.map(l => {
            return {
                name: l.name,
                nativeName: `${l.name.toUpperCase()} - ${l.nativeName}`
            }
        }) : langs;
    }
}
