import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Config, EmitterService, LAYOUT_RESIZED } from 'ngx-myia-core';
import { IAuthState } from 'ngx-myia-http'
import { CultureService } from 'ngx-myia-localization';
import { ReduxStore, ReduxView } from 'ngx-myia-redux';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { authReducerKey } from '../redux/authReducers';
import { AuthService } from '../services/authService';
import { AuthView } from './authView';
import { AccountRoutePath } from '../routes';
import { StatusBarManager } from '../services/statusBarManager';
import { LocalizationService } from 'ngx-myia-localization';

@Component({
    selector: 'top-bar',
    styleUrls: ['./topBar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <nav class="navbar navbar-default navbar-fixed-top" [ngClass]="{'navMenuCollapsed' : showNavMenu}">
            <div class="noPadding navbarContainer">
                <div class="navbar-header noMargin">
                    <button type="button" class="navbar-toggle collapsed" (click)="toggleMenu()" [ngClass]="{force: showCollapseMenu}">
                        <svg-icon name="dots"></svg-icon>
                    </button>
                    <img *ngIf="icon" [src]="icon" class="topBarIcon" [ngClass]="{withContentSidebar: contentHasSidebar}"/>
                    <button class="topBarBackBtn btn btn-link btn-topbar-button" *ngIf="showBackButton" [ngClass]="{withContentSidebar: contentHasSidebar}" (click)="navigateBack()" [tooltip]="backBtnTooltip|trans" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipContainer="body">
                        <svg-icon *ngIf="svgIcon" name="{{svgIcon}}"></svg-icon>
                    </button>
                    <div class="topBarTitleContainer" [ngClass]="{withBackButton: showBackButton || icon, withContentSidebar: contentHasSidebar}">
                        <div class="topBarTitle" *ngIf="title" (click)="toggleTitleListClicked()" [ngClass]="{withList:showTitleSelection}"><span class="titleText"><span class="titleContent">{{title}}</span><span *ngIf="subTitle" class="subTitle {{subTitleClass}}">{{subTitle}}</span></span>
                            <svg-icon className="agendaSelectionBtn" name="arrow-down-thin" *ngIf="showTitleSelection"></svg-icon>
                        </div>
                        <ng-content></ng-content>
                    </div>
                </div>
                <div id="navbar" class="navbar-collapse collapse" [ngClass]="{'in' : showNavMenu}">
                    <ul #navbarRight class="nav navbar-nav navbar-right" [ngClass]="{list: showCollapseMenu}">
                        <li *ngIf="isDebugModeEnabled">
                            <button class="btn btn-link btn-topbar-button debugTools" (click)="switchDevTools()" tooltip="Developer tools (Debug only)" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" tooltipContainer="body">
                                <svg-icon name="bug"></svg-icon>
                                <label>Debugging tools</label></button>
                        </li>
                        <li *ngIf="hasAdminRole && !hideAdminFeatures">
                            <button class="btn btn-link btn-topbar-button adminTools" (click)="switchAdminTools()" tooltip="Admin tools" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" tooltipContainer="body">
                                <svg-icon name="tools"></svg-icon>
                                <label>Admin tools</label></button>
                        </li>
                        <li>
                            <button class="btn btn-link btn-topbar-button buttonCultureSwitch" (click)="switchCulture()" [tooltip]="'Menu|ChangeLanguage'|trans" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" [tooltipDontHideOnChange]="true" tooltipContainer="body"><span
                                class="cultureBtn icon" *ngIf="!changingCulture">{{cultureService.currentCulture}}</span>
                                <progress-indicator-circle *ngIf="changingCulture" indicatorClass="extraSmall cultureLoader"></progress-indicator-circle>
                                <label>{{'Menu|ChangeLanguage' |trans}}</label></button>
                        </li>
                        <li *ngIf="showUserProfile">
                            <button class="btn btn-link btn-topbar-button withBG fixedHeight" (click)="showAccount()" [tooltipHtml]="'Menu|UserProfile'|trans: {email: email}" tooltipPlacement="bottom" tooltipClass="ttUserProfile" tooltipOffset="0,10" tooltipContainer="body">
                                <svg-icon name="user"></svg-icon>
                                <label [innerHtml]="'Menu|UserProfile'|trans: {email: email}|sanitizeHtml"></label></button>
                        </li>
                        <li *ngIf="showLogout">
                            <button class="btn btn-link btn-topbar-button logout" (click)="logout()" [tooltip]="'Menu|Logout'|trans" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" tooltipContainer="body">
                                <svg-icon name="logout"></svg-icon>
                                <label>{{'Menu|Logout' |trans}}</label></button>
                        </li>
                    </ul>
                </div><!--/.nav-collapse -->
                <dev-tools *ngIf="showDevToolsMenu" (close)="switchDevTools()"></dev-tools>
                <admin-tools *ngIf="showAdminToolsMenu" (close)="switchAdminTools()"></admin-tools>
            </div>
            <div class="notificationsContainer"></div>
        </nav>
    `,
})
export class TopBarComponent extends AuthView(ReduxView) implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked { // use AuthView mixin to handle auth properties
    @Input() title: string;
    @Input() subTitle: string;
    @Input() subTitleClass: string;
    @Input() icon: string;
    @Input() svgIcon: string;
    @Input() backUrl: string;
    @Input() backBtnTooltip: string;
    @Input() showLogout: boolean;
    @Input() showTitleSelection: boolean;
    @Input() showUserProfile: boolean = true;
    @Input() contentHasSidebar: boolean;
    @Input() agendaId: string;

    @Output() back: EventEmitter<void> = new EventEmitter<void>();
    @Output() toggleTitleList: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('navbarRight', {static: true}) navbarRight: ElementRef;

    showNavMenu: boolean;

    email: string;

    get showBackButton(): boolean {
        return !!(this.backUrl || this.back.observers.length);
    }

    isDebugModeEnabled: boolean;
    showDevToolsMenu: boolean;

    changingCulture: boolean;

    showAdminToolsMenu: boolean = false;
    showCollapseMenu: boolean = false;

    private _documentClickFunc: Function;
    private _windowResizeSub: Subscription;
    private _layoutResizedSub: any;

    private onResizeBind: EventListener = this.onResize.bind(this);


    constructor(injector: Injector, store: ReduxStore, private _router: Router, private _authService: AuthService, public cultureService: CultureService, private _renderer: Renderer2, private _statusBarManager: StatusBarManager, private _localizationService: LocalizationService) {
        super(injector);
        this.attachToStore(store, [authReducerKey]);
        this.isDebugModeEnabled = Config.get<boolean>('showDevTools', false);
    }

    // Following methods must be defined on component to be compatible with AOT (otherwise Mixins are not supported)
    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    mapStateToProps(state: any) {
        super.mapStateToProps(state);
        const authState = state[authReducerKey] as IAuthState;
        this.email = this.getPropertyFromState(this.email, authState.email);
        return this.propertiesUpdated;
    }

    ngAfterViewInit(): void {
        this.checkNavbarCollapseState();
    }

    ngAfterViewChecked(): void {
        this.checkNavbarCollapseState();
    }

    navigateBack() {
        this.back.emit();
        if (this.backUrl) {
            this._router.navigate([this.backUrl]);
        }
    }

    switchCulture() {
        // TODO implement switch using selection from list/combo
        let userLang = this.cultureService.currentCulture;
        if (userLang === 'en') {
            userLang = 'cs';
        } else {
            userLang = 'en';
        }
        const statusBarMessage = this._statusBarManager.showMessage(this._localizationService.translate('Loading_Culture'));
        this.changingCulture = true;
        this.updateView();
        this.cultureService.use(userLang).subscribe(() => {
            this._statusBarManager.hideMessage(statusBarMessage);
            this.changingCulture = false;
            this.updateView();
        });
    }

    showAccount() {
        this._router.navigate([AccountRoutePath], {
            queryParams: {agendaId: this.agendaId}
        });
    }

    logout() {
        this._authService.logout();
    }

    switchDevTools() {
        this.showDevToolsMenu = !this.showDevToolsMenu;
        this.updateView();
    }

    switchAdminTools() {
        this.showAdminToolsMenu = !this.showAdminToolsMenu;
        this.updateView();
    }

    toggleTitleListClicked() {
        if (this.showTitleSelection) {
            this.toggleTitleList.emit();
        }
    }

    onReduxInit() {
        super.onReduxInit();
        this._windowResizeSub = fromEvent(window, 'resize').pipe(
            debounceTime(250)
        ).subscribe(this.onResizeBind);
        this._layoutResizedSub = EmitterService.getEvent(LAYOUT_RESIZED).subscribe(this.onResizeBind);

    }

    onReduxDestroy() {
        this.unsubscribeGlobalEvents();
        if (this._windowResizeSub) {
            this._windowResizeSub.unsubscribe();
            this._windowResizeSub = null;
        }
        if (this._layoutResizedSub) {
            this._layoutResizedSub.unsubscribe();
            this._layoutResizedSub = null;
        }

    }

    toggleMenu() {
        this.showNavMenu = !this.showNavMenu;
        if (this.showNavMenu) {
            this.subscribeGlobalEvents();
        } else {
            this.unsubscribeGlobalEvents();
        }
    }

    private subscribeGlobalEvents() {
        this.unsubscribeGlobalEvents();

        // handle click events on document to hide event list menu
        this._documentClickFunc = this._renderer.listen('document', 'click', (event) => {
            let current = event.target;
            while (current.tagName !== 'BODY' && current.tagName !== 'HTML') {
                if (current.classList.contains('navbar-toggle')) {
                    return;
                }
                current = current.parentNode;
                if (!current) {
                    // dom is not child of body (probably already removed from DOM)
                    return;
                }
            }
            this.unsubscribeGlobalEvents();
            this.showNavMenu = false;
            this.updateView();
        });
    }

    private unsubscribeGlobalEvents() {
        if (this._documentClickFunc) {
            this._documentClickFunc();
            this._documentClickFunc = null;
        }
    }

    private onResize() {
        if (this.showNavMenu) {
            this.unsubscribeGlobalEvents();
            this.showNavMenu = false;
            this.updateView();
        }
        this.checkNavbarCollapseState();
    }

    private checkNavbarCollapseState() {
        const showCollapseMenu = this.navbarRight && this.navbarRight.nativeElement && this.navbarRight.nativeElement.getBoundingClientRect().top > 0;
        if (this.showCollapseMenu !== showCollapseMenu) {
            this.showCollapseMenu = showCollapseMenu;
            this.updateView();
        }
    }
}


