
        <div class="topButtons">
            <button type="button" class="btnPlain" (click)="close()">
                <svg-icon name="cancel"></svg-icon>
            </button>
        </div>
        <div class="itemsBlock">
            <div class="enumItem" *ngFor="let value of values" [ngClass]="{selected: selectedValues[value.id], active: valuesCnt[value.id]}" (click)="setFilter(value.id)">
                <svg-icon name="ok"></svg-icon>
                <div>{{value.title|trans}}<span *ngIf="valuesCnt[value.id]"> ({{valuesCnt[value.id]}})</span></div>
            </div>
        </div>
        <div class="buttons">
            <button type="button" class="btnPlain" [disabled]="clearDisabled" (click)="clear()">Reset filter</button>
        </div>
    