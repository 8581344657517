import { createState, updateState } from 'ngx-myia-redux';
import { HIDE_ADMIN_FEATURES } from './adminActions';

export const adminReducerKey = 'admin';

export interface IAdminState {
    _hideAdminFeatures: boolean;
}
const initialState = createState<IAdminState>(
    {
        _hideAdminFeatures: false
    });

function adminReducer(state = initialState, action: any) {
    switch (action.type) {
        case HIDE_ADMIN_FEATURES:
            return updateState(state, (newState: IAdminState) => {
                newState._hideAdminFeatures = true;
            });
        default:
            return state;
    }
}

export default {
    [adminReducerKey]: adminReducer
};
