import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormControl, FormGroup, Validators } from '@angular/forms';
import { getElementScrollbarWidth, getNewComponentId, getParentScroll, Logger, scrollToViewportWhenNeeded } from 'ngx-myia-core';
import { CultureService } from 'ngx-myia-localization';
import { LocalizationService } from 'ngx-myia-localization';
import { Observable, Observer, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { ICompleterItem } from '../entities/completerItem.interface';
import { CompleterService } from '../services/completerService';

// keyboard events
const KEY_DW = 40; // down
const KEY_RT = 39; // right
const KEY_UP = 38; // up
const KEY_LF = 37; // left
const KEY_ES = 27; // escape
const KEY_EN = 13; // enter
const KEY_TAB = 9; // tab

export enum ShowListIconMode {
    hidden,
    show,
    showWhenEmpty
}

const dropDownListVerticalSpace = 20;

@Component({
    selector: 'input-text-field',
    styleUrls: ['./inputTextField.component.scss'],
    template: `
        <div class="textFieldGroup" [ngClass]="{required: isRequired, modified: isModified, withLabel: label, hasValue: value | hasValue, init: isInitialized, hasPlaceHolder: placeholder}">
            <input #inputEl [id]="fieldName" [attr.maxlength]="maxLength" [attr.placeholder]="placeholder" [type]="isPassword ? 'password' : 'text'" (blur)="onBlur()" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (focus)="onFocus()" [formControl]="control" [ngClass]="{hasValue: value | hasValue, password: isPassword, hasPlaceHolder: placeholder}" [readonly]="readonly" [tabIndex]="tabIndex" [attr.autocapitalize]="autoCapitalize" [attr.autocomplete]="disableBrowserAutoComplete || isPassword || completerService ? 'off' : 'on'" [attr.autocorrect]="disableBrowserAutoComplete || isPassword || completerService ? 'off' : 'on'" [attr.autocapitalize]="disableBrowserAutoComplete || isPassword || completerService ? 'off' : 'on'" [tooltip]="message" [tooltipPlacement]="messagePlacement" [tooltipEnable]="!value" [tooltipDelay]="0"/>
            <div *ngIf="!readonly" class="reqMark line"></div>
            <div *ngIf="label" class="bar"></div>
            <label *ngIf="label">{{label}}</label>
            <div class="autoCompleteIcon" *ngIf="showListIcon !== showListIconMode.hidden && !isDisabled && (!value || showListIcon === showListIconMode.show)" (click)="showAutoCompleteList($event)">
                <svg-icon name="drop-down-list"></svg-icon>
            </div>
            <div #autoCompleteDropDown *ngIf="completerOpened" class="completerDropDownBlock" [ngClass]="{withValue: value}">
                <div *ngIf="isSearching && displaySearching && !autoCompleteItems?.length" class="completer-searching">{{textSearching}}</div>
                <div *ngIf="!isSearching && displayNoResults && (!autoCompleteItems?.length)" class="completer-no-results">{{textNoResults}}</div>
                <progress-indicator-bar *ngIf="isSearching && displaySearching && autoCompleteItems?.length" indicatorClass="completer-searching-loader blockLoader fromLeft"></progress-indicator-bar>
                <div class="completer-row-wrapper" *ngFor="let item of autoCompleteItems; let rowIndex=index; trackBy: trackCompleterItem">
                    <div class="completer-row" [ngClass]="{selected: highlightedItem === item, current: item.title === value}" (click)="selectCompleterItem(item)" (mouseEnter)="highlightItem(item)">
                        <div *ngIf="item.image || item.image === ''" class="completer-image-holder">
                            <img *ngIf="item.image != ''" src="{{item.image}}" class="completer-image"/>
                            <div *ngIf="item.image === ''" class="completer-image-default"></div>
                        </div>
                        <div class="completer-item-text" [ngClass]="{'completer-item-text-image': item.image || item.image === '' }">
                            <div class="title">{{item.title}}</div>
                            <div class="description" *ngIf="item.description">{{item.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <control-messages *ngIf="!readonly" [messages]="control|validationErrors"></control-messages>
            <button *ngIf="isPassword && value" class="togglePasswordBtn" type="button" (mousedown)="inputEl.type='text'" (mouseup)="inputEl.type='password'" [disabled]="isDisabled" tabindex="-1">
                <svg-icon name="eye"></svg-icon>
            </button>
        </div>
    `
})
export class InputTextFieldComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() isPassword: boolean;
    @Input() isModified: boolean;

    @Input() maxLength: number;

    @ViewChild('autoCompleteDropDown', {static: false}) set autoCompleteDropDown(element: ElementRef) {
        if (element) {
            // scroll to selected value
            console.log('autoCompleteRendered');
            this.updateDropdownListPosition(element);
            const selectedEl = element.nativeElement.querySelector('.completer-row.current');
            if (selectedEl) {
                setTimeout(() => {
                    scrollToViewportWhenNeeded(selectedEl, {duration: 0});
                });
            }
        }
    }


    get isRequired(): boolean {
        return this._isRequired;
    }

    @Input() set isRequired(val: boolean) {
        this._isRequired = val;
        this.updateValidators();
    }

    @Input() set classNames(value: string) {
        this._classNames = value;
        this.updateHostClasses();
    }

    @HostBinding('class') hostClasses: string;

    get isDisabled(): boolean {
        return this._disabled;
    }

    @Input('isDisabled') set isDisabled(val: boolean) {
        this._disabled = val;
        if (this.control) {
            if (val) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
    }

    @Input() readonly: boolean;
    @Input() tabIndex: string;
    @Input() validator: any;
    @Input() label: string;
    @Input() formGroupRef: FormGroup;
    @Input() fieldName: string;
    @Input() placeholder: string; // text to show when value is empty and label should be displayed below the field
    @Input() message: string;
    @Input() messagePlacement: string = 'bottom';
    @Input() disableBrowserAutoComplete: boolean;
    @Input() autoCapitalize: string = '';

    @Input() set textSearching(text: string) {
        this._textSearching = text;
    }

    get textSearching(): string {
        return this._textSearching;
    }

    @Input() set textNoResults(text: string) {
        this._textNoResults = text;
    }

    get textNoResults(): string {
        return this._textNoResults;
    }

    get value(): any {
        return this._value;
    }

    @Input() set value(v: any) {
        const valueChanged = v !== this._value && !(this.isNaN(v) && this.isNaN(<any>this._value));
        if (valueChanged || !this._valueInitialized) {
            this._value = v;
            if (this._valueInitialized) {
                this.valueChange.emit(v);
            } else {
                this._valueInitialized = true;
            }
            if (this.control) {
                this.control.setValue(this._value);
            }
        }
    }

    @Input() updateOn: string;

    @Input() detectExternalChanges: boolean;
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() blur: EventEmitter<void> = new EventEmitter<void>();
    @Output() focus: EventEmitter<void> = new EventEmitter<void>();
    isInitialized: boolean;

    @ViewChild('inputEl', {static: true}) inputEl: ElementRef;

    control: FormControl;

    // auto complete related properties
    @Input() completerService: CompleterService;
    @Input() minSearchLength = 1;
    @Input() clearUnselected = false;
    @Input() autoMatch: string = null;

    @Input() set showListIcon(value: ShowListIconMode | string) {
        if (typeof value === 'string') {
            this._showListIcon = ShowListIconMode[value];
        } else {
            this._showListIcon = value;
        }
    }

    get showListIcon(): ShowListIconMode | string {
        return this._showListIcon;
    }

    @Output() autoCompleted = new EventEmitter<ICompleterItem>();
    autoCompleteItems: ICompleterItem[];
    highlightedItem: ICompleterItem;
    isSearching: boolean = false;
    completerOpened: boolean = false;
    completerEnabled: boolean = false;
    displaySearching = true;
    displayNoResults = true;
    showListIconMode = ShowListIconMode;

    private _showListIcon = ShowListIconMode.hidden;
    private _textNoResults: string;
    private _textSearching: string;

    private _valueChangeDetectionInterval: any;
    private _disabled: boolean = false;
    private _isRequired: boolean = false;

    private _classNames: string;
    private _value: string = null;
    private _onCultureChange: any;

    private _controlName: string;
    private _valueInitialized: boolean;

    private _hasFocus: boolean;
    private _isFromCompleter: boolean; // is set when text was set as user selection from auto complete list

    private _cancelBlurAction: boolean; // cancel blur action when clicked on listIcon
    private _parentScroll: HTMLElement;

    constructor(private _localizationService: LocalizationService, private _cultureService: CultureService, private _logger: Logger, private _changeDetectorRef: ChangeDetectorRef, private _element: ElementRef) {
        // subscribe to onChange event, in case the culture changes
        this._onCultureChange = this._cultureService.onChange.subscribe(() => {
            // update timezone (is localized)
            this.updatedLocalizedTexts();
        });
    }

    updatedLocalizedTexts() {
        this._textNoResults = this._localizationService.translate('General|Auto_Complete_No_results');
        this._textSearching = this._localizationService.translate('General|Auto_Complete_Searching');
    }


    ngOnInit() {
        this.updatedLocalizedTexts();
        this.updateHostClasses();
        this.control = new FormControl({value: this._value, disabled: this._disabled}, {updateOn: this.updateOn} as AbstractControlOptions);
        this.control.valueChanges.subscribe(this.updateData.bind(this));
        if (this.formGroupRef) {
            this._controlName = this.fieldName || getNewComponentId();
            this.formGroupRef.addControl(this._controlName, this.control);
        }
        this.updateValidators();

        if (this.completerService) {
            this.completerService.subscribe(items => {
                this.isSearching = false;
                this.autoCompleteItems = items;
                this._changeDetectorRef.markForCheck();
                //this._logger.log('Auto complete: ' + (items ? items.length : 0));
            });
            this.control.valueChanges.pipe(
                debounceTime(200),
                distinctUntilChanged()
            ).subscribe(query => {
                this.performAutoCompletion(query);
            });
        }

        if (this.textSearching === 'false') {
            this.displaySearching = false;
        }
    }

    ngOnDestroy() {
        if (this.completerService) {
            this.completerService.cancel();
        }
        this.stopValueChangeDetection();
        if (this._onCultureChange) {
            this._onCultureChange.unsubscribe();
            this._onCultureChange = undefined;
        }
        if (this.formGroupRef) {
            this.formGroupRef.removeControl(this._controlName);
        }
    }

    ngAfterViewInit() {
        if (this.detectExternalChanges) {
            this.startValueChangeDetection();
        }
    }

    ngAfterViewChecked() {
        if (!this.readonly && !this._parentScroll) {
            this.checkParentScroll();
        }
    }


    updateData(newValue: any) {
        if (this.value !== newValue) {
            this.value = newValue;
            this.isInitialized = true;
        }
    }

    setFocus() {
        if (this.inputEl) {
            this.inputEl.nativeElement.focus();
        }
    }

    onFocus() {
        this._hasFocus = true;
        this.focus.emit();
        this.completerEnabled = true;
    }

    onBlur() {
        this._hasFocus = false;
        this.blur.emit();
    }

    onKeyUp(event: any) {
        if (event.keyCode === KEY_ES) {
            this.closeCompleter();
        }
    }

    onKeyDown(event: any) {
        this.completerEnabled = true;

        if (this.completerOpened) {
            if (event.keyCode === KEY_EN) {
                if (this.highlightedItem) {
                    event.preventDefault();
                }
                this.selectCompleterItem(this.highlightedItem);
            } else if (event.keyCode === KEY_DW) {
                event.preventDefault();
                this.highlightNextItem(1);
            } else if (event.keyCode === KEY_UP) {
                event.preventDefault();
                this.highlightNextItem(-1);
            } else if (event.keyCode === KEY_ES) {
                // This is very specific to IE10/11 #272
                // without this, IE clears the input text
                event.preventDefault();
            }
        } else {
            if (event.keyCode === KEY_DW) {
                event.preventDefault();
                if (this.completerService) {
                    this.performAutoCompletion(this._value);
                }
            }
        }
    }

    @HostListener('blur', ['$event'])
    public onComponentBlur($event: any) {
        setTimeout(
            () => {
                if (this._cancelBlurAction) {
                    this._cancelBlurAction = false;
                    return;
                }
                if (this.autoMatch && this.completerOpened && !this._isFromCompleter && this.autoCompleteItems) {
                    // auto match from listed items
                    const matchedItem = this.autoCompleteItems.find(item => item.originalObject[this.autoMatch] === this.value);
                    if (matchedItem) {
                        this.selectCompleterItem(matchedItem);
                        return;
                    }
                }
                this.closeCompleter();
                if (this.clearUnselected && !this._isFromCompleter) {
                    this.value = '';
                }
            },
            200
        );
    }

    stopValueChangeDetection() {
        if (this._valueChangeDetectionInterval) {
            clearInterval(this._valueChangeDetectionInterval);
            this._valueChangeDetectionInterval = null;
        }
    }

    startValueChangeDetection() {
        // check input value periodically to detect input value change from outside (e.g. browser password managers/plugins)
        this._valueChangeDetectionInterval = setInterval(
            () => {
                const value = this.inputEl.nativeElement.value;
                if (this._value !== value) {
                    this._valueInitialized = true; // set this flag to enable valueChange event
                    this.updateData(value);
                }
            },
            500);
    }

    trackCompleterItem(index: number, item: ICompleterItem): any {
        return item.originalObject;
    }

    highlightItem(item: ICompleterItem) {
        this.highlightedItem = item;
    }

    selectCompleterItem(item: ICompleterItem) {
        this._isFromCompleter = true;
        this.closeCompleter();
        this.autoCompleted.emit(item);
    }

    showAutoCompleteList($event: any) {
        $event.stopPropagation();
        $event.preventDefault();
        this._cancelBlurAction = !this.completerOpened;
        if (!this.completerOpened) {
            this.performAutoCompletion('', true);
        } else {
            this.closeCompleter();
            this.setFocus();
        }
    }

    private updateHostClasses() {
        this.hostClasses = 'inputField inputTextField ' + (this._classNames || '');
    }


    private highlightNextItem(delta: number) {
        let highlightedIndex = this.highlightedItem ? this.autoCompleteItems.findIndex(item => item.originalObject === this.highlightedItem.originalObject) : -1;
        if (highlightedIndex === -1) {
            highlightedIndex = delta > 0 ? 0 : this.autoCompleteItems.length - 1;
        } else {
            highlightedIndex += delta;
        }
        if (highlightedIndex >= 0 && highlightedIndex < this.autoCompleteItems.length) {
            this.highlightItem(this.autoCompleteItems[highlightedIndex]);
        }
        this._changeDetectorRef.markForCheck();
    }

    private closeCompleter() {
        this.completerEnabled = false;
        this.completerOpened = false;
        this.highlightedItem = null;
        this.autoCompleteItems = null;
        this._changeDetectorRef.markForCheck();
        window.document.removeEventListener('click', this.closeCompleter.bind(this));
    }

    private performAutoCompletion(query: string, force?: boolean) {
        if (this.completerEnabled || force) {
            this._isFromCompleter = false;
            if (force || (query && query.length >= this.minSearchLength)) {
                this.completerOpened = true;
                this.highlightedItem = null;
                this.isSearching = true;
                this._changeDetectorRef.markForCheck();
                this.completerService.search(query);
                if (force) {
                    window.document.addEventListener('click', this.closeCompleter.bind(this));
                }
            } else {
                this.isSearching = false;
                this.completerService.cancel();
                this.autoCompleteItems = null;
                this.closeCompleter();
                this._logger.log('Cancel auto complete');
            }
        }
    }

    private updateValidators() {
        if (this.control) {
            let validators = this.validator ? [this.validator] : [];
            if (this._isRequired) {
                validators.push(Validators.required);
            }
            this.control.setValidators(Validators.compose(validators));
            if (this.completerService) {
                this.control.setAsyncValidators(this._validate.bind(this))
            }
            this.control.updateValueAndValidity();
        }
    }

    private isNaN(value: any) {
        return typeof value !== 'string' && isNaN(value);
    }

    private _validate(control: AbstractControl) {
        if (control.value && this.completerService) {
            return new Observable((observer: Observer<any>) => {
                this.completerService.asObservable().pipe(
                    tap(items => {
                        const result = items.find(i => i.title === control.value) ? null : {
                            wrongAutoCompleteValue: true
                        };
                        observer.next(result);
                        observer.complete();
                    })
                ).subscribe();
            });
        }
        return of(null);
    }

    private updateDropdownListPosition(dropDownElement: ElementRef) {
            const dropDownContainer = dropDownElement.nativeElement;
            const dropDownBounds = this._element.nativeElement.getBoundingClientRect();
            let dropDownListBounds = dropDownContainer.getBoundingClientRect();
            const viewBoundsEl = this._parentScroll || document.body;
            const parentBounds = viewBoundsEl.getBoundingClientRect();
            const maxRight = parentBounds.right - getElementScrollbarWidth(this._parentScroll);
            const margin = 5; // margin
            //console.log(`p: ${JSON.stringify(parentBounds)},\r\nd: ${JSON.stringify(dropDownListBounds)}`);
            dropDownContainer.style.maxWidth = `${maxRight - margin - dropDownListBounds.left}px`;
            const expandUp = dropDownBounds.bottom + dropDownListBounds.height > parentBounds.bottom && (dropDownBounds.top - parentBounds.top) > parentBounds.height / 2;
            const expandLeft = dropDownBounds.right + dropDownListBounds.width > parentBounds.right && (dropDownBounds.left - parentBounds.left) > parentBounds.width / 2;
            const maxHeight = expandUp ? dropDownBounds.top - parentBounds.top: parentBounds.bottom - dropDownBounds.bottom;
            // set max height to avoid overflow
            dropDownContainer.style.maxHeight = `${maxHeight - dropDownListVerticalSpace}px`;
            // update dropDownList bounds after maxHeight set
            dropDownListBounds = dropDownContainer.getBoundingClientRect();
            // check vertical overflow
            if  (expandUp) {
                // expand dropdown list to the top
                dropDownContainer.style.marginTop = `-${dropDownListBounds.height}px`;
                dropDownContainer.style.top = '0px';
            }
            else {
                dropDownContainer.style.marginTop = 'auto';
                dropDownContainer.style.top = 'auto';
            }
            // check horizontal overflow
            if  (expandLeft) {
                // expand dropdown list to the top
                dropDownContainer.style.marginLeft = `-${dropDownListBounds.width - this._element.nativeElement.offsetWidth + 1}px`;
            }
            else {
                dropDownContainer.style.marginLeft = 'auto';
            }

    }

    private checkParentScroll() {
        if (this._element) {
            this._parentScroll = getParentScroll(this._element.nativeElement.parentNode);
        }
    }
}
