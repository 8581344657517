import { IFilterAngularComp } from '@ag-grid-community/angular';
import { GridApi } from '@ag-grid-community/core/dist/es6/gridApi';
import { Component } from "@angular/core";
import { IFilterParams, IDoesFilterPassParams } from '@ag-grid-community/core';
import { AgGridDetailRow } from './agGridDetailRow';

export interface IEnumItemDef {
    id: any,
    title: string
}

export interface IEnumFilterParams extends IFilterParams {
    values: Array<IEnumItemDef>;
}

@Component({
    styleUrls: ['./agGridEnumFilter.component.scss'],
    template: `
        <div class="topButtons">
            <button type="button" class="btnPlain" (click)="close()">
                <svg-icon name="cancel"></svg-icon>
            </button>
        </div>
        <div class="itemsBlock">
            <div class="enumItem" *ngFor="let value of values" [ngClass]="{selected: selectedValues[value.id], active: valuesCnt[value.id]}" (click)="setFilter(value.id)">
                <svg-icon name="ok"></svg-icon>
                <div>{{value.title|trans}}<span *ngIf="valuesCnt[value.id]"> ({{valuesCnt[value.id]}})</span></div>
            </div>
        </div>
        <div class="buttons">
            <button type="button" class="btnPlain" [disabled]="clearDisabled" (click)="clear()">Reset filter</button>
        </div>
    `
})
export class AgGridEnumFilter implements IFilterAngularComp {
    values: Array<IEnumItemDef>;
    valuesCnt: any;
    selectedValues: any = {};
    clearDisabled: boolean = true;
    private _params: IFilterParams;
    private _hideFilter: () => void;

    constructor() {
    }

    agInit(params: IEnumFilterParams): void {
        this._params = params;
        this.values = params.values;
        this.updateValuesCounters();
    }

    afterGuiAttached(params: any) {
        this._hideFilter = params.hidePopup;
    }

    setFilter(value: any) {
        console.log('Set filter: ' + value);
        const checked = !this.selectedValues[value];
        if (checked) {
            this.selectedValues[value] = true;
        } else {
            delete this.selectedValues[value];
        }

        this.clearDisabled = !this.isFilterActive();
        this._params.filterChangedCallback();
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return AgGridDetailRow.isDetailRow(params.data) || !!this.selectedValues[params.data.status];
    }

    getModel(): any {
        return {value: this.selectedValues};
    }

    isFilterActive(): boolean {
        return !!Object.keys(this.selectedValues).length;
    }

    setModel(model: any): void {
        this.selectedValues = model ? model.value : {};
    }

    clear() {
        this.selectedValues = {};
        this.clearDisabled = true;
        this._params.filterChangedCallback();
    }

    close() {
        this._hideFilter();
    }

    private updateValuesCounters() {
        this.valuesCnt = {};
        this.values.forEach(v => {
            this.valuesCnt[v.id] = 0;
        });
        this._params.api.forEachNodeAfterFilter((node) => {
            this.valuesCnt[node.data.status]++;
        });

    }
}
