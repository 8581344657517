import { Injectable } from '@angular/core';
import { getNewComponentId, readOnlyEntity } from 'ngx-myia-core';
import { ReduxStore } from 'ngx-myia-redux';
import { IStatusBarMessage } from '../entities/statusBarMessage.interface';
import { addStatusBarMessage, clearAllStatusBarMessages, removeStatusBarMessage, showStatusBarBusyLine, updateStatusBarMessage } from '../redux/statusBarActions';

const defaultMessageOptions = {
    centered: false,
    key: <string>null
};

@Injectable({ providedIn: 'root' })
export class StatusBarManager {
    private _msgCounter: number = 0;

    constructor(private _store: ReduxStore) {
    }

    showMessage(text: string, options?: {key?: string, centered?: boolean, timeToShow?: Date}): IStatusBarMessage {
        const msgOptions = {...defaultMessageOptions, ...options};
        const msg = readOnlyEntity<IStatusBarMessage>({id: getNewComponentId(),  messageKey: msgOptions.key || this.getNewMsgKey(), messageText: text, centered: msgOptions.centered, timeToShow: msgOptions.timeToShow});
        this._store.dispatch(addStatusBarMessage(msg));
        return msg;
    }

    updateMessage(message: IStatusBarMessage) {
        this._store.dispatch(updateStatusBarMessage(message));
    }

    hideMessage(message: IStatusBarMessage) {
        if (message) {
            this._store.dispatch(removeStatusBarMessage(message.messageKey));
        }
    }

    clearAllMessages() {
        this._store.dispatch(clearAllStatusBarMessages());
    }


    showBusyLine(show: boolean, key: string) {
        this._store.dispatch(showStatusBarBusyLine(show, key));
    }

    private getNewMsgKey(): string {
        this._msgCounter++;
        return `msg` + this._msgCounter;
    }
}
