
        <div class="_devToolsMenu_">
            <div class="panelTitle">Development tools</div>
            <div class="closeBtnWrapper">
                <button class="btn btn-link btn-topbar-button" type="button" (click)="closeDevTools()">
                    <svg-icon name="cancel"></svg-icon>
                </button>
            </div>
            <div class="section">
                <input-dropdown [value]="loggerLevel" label="Log level" [items]="loggerLevels" [itemTitlePath]="'value'" (valueChange)="loggerLevelChanged($event)"></input-dropdown>
            </div>
            <div class="section">
                <div class="title">Token management:</div>
                <button type="button" (click)="invalidateToken(true, false)">Invalidate token</button>
                <button type="button" (click)="invalidateToken(false, true)">Invalidate refresh token</button>
            </div>
            <div class="section">
                <div class="title">Toasts:</div>
                <div>
                    <button type="button" (click)="showToast('info')">Info</button>
                    <button type="button" (click)="showToast('warning')">Warning</button>
                    <button type="button" (click)="showToast('error')">Error</button>
                    <button type="button" (click)="showToast('success')">Success</button>
                </div>
                <div class="subTitle">With toast key:</div>
                <div>
                    <button type="button" (click)="showToast('info', '_toastKey_')">Info</button>
                    <button type="button" (click)="showToast('warning', '_toastKey_')">Warning</button>
                    <button type="button" (click)="showToast('error', '_toastKey_')">Error</button>
                    <button type="button" (click)="showToast('success', '_toastKey_')">Success</button>
                </div>
                <div class="subTitle">Custom icon:</div>
                <button type="button" (click)="showCustomToast()">Custom info</button>
            </div>
        </div>
    